import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAccountCreationPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSideContainerPanel, HUDSidePanel, HUDInput, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDIoTide } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";

import PBGViewCell from "../HUDComponents/PBGViewCell.js";











const ioTide = new HUDIoTide();

function PatrolBuddyGoGlobalViewPanel(patrolBuddyGoGlobalViewPanelConfigurationSettings) 
{
    const {
         
         ...rest
    } = patrolBuddyGoGlobalViewPanelConfigurationSettings;
    
    const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
    
    const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
    
    const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Global View");
    
    const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
    
    const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
    
    const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
    
    const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
    
    const [restrictedAccessed, setRestrictedAccessed] = useState(false);
    
    const [usernameLabelHover, setUsernameLabelHover] = useState(false);
    
    const [sideContainerPanelVisible, setSideContainerPanelVisible] = useState(false);
    
    const [pbgSearchInputInFocus, setPBGSearchInputInFocus] = useState(false);
    
    const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
    
    const [sidePanelVisible, setSidePanelVisible] = useState(false);
    
    const [pbgSearchInputFocus, setPBGSearchInputFocus] = useState(false);
    
    const [pbgSearchInputValue, setPBGSearchInputValue] = useState("");
    
    const [currentPBGCells, setCurrentPBGCells] = useState([]);
    
    const [pbgCells, setPBGCells] = useState([]);
    
    const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
    
    const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
    
    
    
    
    
    
    
    
    
    
    
    const globalViewPanelRef = useRef(null);
    
    const sidePanelRef = useRef(null);
    
    
    
    
    
    
    
    
    
    
    
    const pbgPanelNavigator = useNavigate();
    
    var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
    
    var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("Global View");
    
    let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
    
    let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
    
    
    
    
    
    
    
    
    
    
    const handlePBGSearchInputUpdate = (e) => {
         const inputValue = e.target.value;
         
         setPBGSearchInputValue(inputValue);
         
         if (inputValue.trim() === "")
         {
              setCurrentPBGCells(pbgCells);
              
              return;
         }
         
         const searchedPBGs = pbgCells.filter(pbgCell => `${pbgCell.pbgDisplayName} (${pbgCell.serialK})`.includes(inputValue) || pbgCell.user && (pbgCell.user.displayName.includes(inputValue) || pbgCell.user.username.includes(inputValue)));
         
         setCurrentPBGCells(searchedPBGs);
         
         return;
    };
    
    const updatePBGList = (pbgList, newPBG) => {
          const existingIndex = pbgList.findIndex(pbg => pbg.serialK === newPBG.serialK);
          if (existingIndex !== -1) {
               const updatedPBGList = [...pbgList];
               updatedPBGList[existingIndex] = newPBG;
               return updatedPBGList;
          } else {
               return [...pbgList, newPBG];
          }
    };
    
    const processCurrentPBGs = async (incomingPBGData) => {
         const currentPBGData = incomingPBGData.serialK ? {
               serialK : incomingPBGData.serialK,
               locality : {
                    county : "Clayton County",
                    state : "GA"
               },
               pbgDisplayName : "Car 3247",
               pbgSignalSRC : `https://gpn.patrolbuddygo.com/BB08`,
               pbgViewSRC : "rtsp://localhost:8554/leftcam",
               pbgStatus : `Online ~ ${HUDUniversalHUDUtilityManager.ConvertUTCDateToDateDisplay(parseFloat(incomingPBGData.connectedOn), 1)}`,
               user : {
                    accessLevel : {
                         accountAccessLevelP : "Patrol Chief",
                         accountAccessLevelID : 3,
                         accountAccessLevelR : 2
                    },
                    displayName : "Joshua Carr",
                    username : "josh.carr",
                    userID : 6
               }
         } : null;
         console.log(currentPBGData);
         if (!currentPBGData) { return; }
         
         await Delay(869);
         
         if (pbgSearchInputValue.trim() === "")
         {
              setCurrentPBGCells((prCurrentPBGCells) => updatePBGList(prCurrentPBGCells, currentPBGData));
         }
         
         setPBGCells((prPBGCells) => updatePBGList(prPBGCells, currentPBGData));
         
         return;
    };
    
    const handleLoadPBGs = () => {
         /*const pbgCellsData = [
         {
               serialK : "BB08",
               locality : {
                    county : "Clayton County",
                    state : "GA"
               },
               pbgDisplayName : "Car 3247",
               pbgSignalSRC : "http://192.168.2.107:5069",
               pbgViewSRC : "rtsp://localhost:8554/leftcam",
               pbgStatus : "Offline ~ ---%",
               user : {
                    accessLevel : {
                         accountAccessLevelP : "Patrol Chief",
                         accountAccessLevelID : 3,
                         accountAccessLevelR : 2
                    },
                    displayName : "Joshua Carr",
                    username : "josh.carr",
                    userID : 6
               }
         },
         {
               serialK : "BB08",
               locality : {
                    county : "Clayton County",
                    state : "GA"
               },
               pbgDisplayName : "Car 3569",
               pbgSignalSRC : "http://192.168.2.107:5069",
               pbgViewSRC : "rtsp://localhost:8554/leftcam",//"https://youtu.be/9k_sg8rhsgk",
               pbgStatus : "Online ~ 100%",
               user : {
                    accessLevel : {
                         accountAccessLevelP : "Patrol Chief",
                         accountAccessLevelID : 3,
                         accountAccessLevelR : 2
                    },
                    displayName : "Kevin Roberts",
                    username : "kevin.roberts",
                    userID : 7
               }
         },
         {
               serialK : "BB08",
               locality : {
                    county : "Cobb County",
                    state : "GA"
               },
               pbgDisplayName : "Car 8699",
               pbgSignalSRC : "http://192.168.2.107:5069",
               pbgViewSRC : "rtsp://localhost:8554/leftcam",//"https://youtu.be/otX-buqqS6Q",
               pbgStatus : "Online ~ 100%",
               user : {
                    accessLevel : {
                         accountAccessLevelP : "Patrol Chief",
                         accountAccessLevelID : 3,
                         accountAccessLevelR : 2
                    },
                    displayName : "Capt. Holt",
                    username : "capt.holt",
                    userID : 9
               }
         }
         ];
         
         setCurrentPBGCells(pbgCellsData);
         
         setPBGCells(pbgCellsData);*/
         
         return;
    };
    
    const handleMouseMove = (e) => {
         HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 69, e);
         
         HUDUniversalHUDUtilityManager.CheckMouseNearEdge(e, sideContainerPanelVisible ? 427 : 27.69, "right", globalViewPanelRef, setSideContainerPanelVisible);
         
         return;
    };
    
    
    
    
    
    
    
    
    
    
    
    useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return;
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     useEffect(() => {
          if (!signedIn) { return; }
          
          handleLoadPBGs();
     }, [signedIn]);
     
     useEffect(() => {
         ioTide.createIO("globalViewNet", "https://utilities.patrolbuddygo.com:9269");
         
         ioTide.onEvent("globalViewNet", "got-online-patrolbuddy", (patrolBuddyData) => {
            processCurrentPBGs(patrolBuddyData);
         });
         
         ioTide.setErrorCallback((socketName, error) => {
            console.error(`Custom error handler for ${socketName}:`, error);
         });
         
         ioTide.enableLogging("globalViewNet");
         
         const globalViewNT = setInterval(() => {
              ioTide.emitEvent("globalViewNet", "get-online-patrolbuddy", { serial : "PBG-BB08" });
         }, 2169);
         
         return () => {
              ioTide.closeSocket("globalViewNet");
              
              clearInterval(globalViewNT);
         };
     }, []);
    
    
    
    
    
    
    
    
    
    
    
    return (
          <div
               className = "gr-scroller"
               
               ref = {globalViewPanelRef}
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <HUDSidePanel
                    sidePanelRef = {sidePanelRef}
                    
                    sidePanelMinimizeTabButtonConfigurationSettings = {{
                         background : "transparent",
                         color : "#A1A1A1"
                    }}
                    
                    sidePanelButtonUnderlineConfigurationSettings = {{
                         background : "linear-gradient(to right, #A1A1A1, white)"
                    }}
                    
                    sidePanelHeaderLogoConfigurationSettings = {{
                         transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                         scale : sidePanelLogoHover ? "1.069" : "1",
                         cursor : "pointer",
                         marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                    }}
                    
                    sidePanelContainerConfigurationSettings = {{
                         background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                    }}
                    
                    sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
                    
                    sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverEnterAction = {() => {
                         setSidePanelLogoHover(true);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverExitAction = {() => {
                         setSidePanelLogoHover(false);
                         
                         return;
                    }}
                    
                    sidePanelButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoAction = {() => {
                         window.open("https://generalizedrobotics.com", "_blank")
                         
                         return;
                    }}
                    
                    logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                    
                    sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                    
                    sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                    
                    minimizeTabPosition = "center-right"
                    
                    hideType = "Full"
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
               />
               
               <HUDIcon
                  iconConfigurationSettings = {{
                       position : "fixed",
                       transition : "all 0.369s ease",
                       transform : sideContainerPanelVisible ? "rotate(180deg)" : "rotate(0deg)",
                       color : sideContainerPanelVisible ? "#9FD1D9" : "#A1A1A1",
                       height : "1.269rem",
                       zIndex : 869,
                       right : sideContainerPanelVisible ? "398.69px" : "7.69px",
                       top : "calc(50%)"
                  }}
                  
                  name = "fa-chevron-left"
               />
               
               <HUDSideContainerPanel
                  sideContainerConfigurationSettings = {{
                       background : !sideContainerPanelVisible ? "#9FD1D9" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                       width : "377.69px",
                       paddingLeft : "0.69rem"
                  }}
                  
                  controlledOpen = {sideContainerPanelVisible}
                  
                  displayCloseButton = {false}
                  
                  displayBackDrop = {false}
                  
                  position = "right"
               >
                  {sideContainerPanelVisible ? (
                       <div
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 alignItems : "center",
                                 width : "calc(100%)"
                            }}
                       >
                       <HUDInput
                            inputContainerConfigurationSettings = {{
                                 position : "relative",
                                 display : "flex",
                                 background : "transparent",
                                 borderRadius : "0.69rem",
                                 outline : "none",
                                 borderLeft : "none",
                                 borderTop : "none",
                                 boxShadow : pbgSearchInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                 borderBottom : pbgSearchInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                 borderRight : pbgSearchInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                 color : pbgSearchInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                 scale : pbgSearchInputInFocus ? "1.069" : "1",
                                 transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                 fontFamily : "ModeNine",
                                 fontSize : "1.069rem",
                                 height : "2.269rem",
                                 width : "calc(91.69%)",
                                 marginBottom : "0.869rem",
                                 marginTop : "0.69rem",
                            }}
                            
                            placeholder = "Search for a PatrolBuddy..."
                            
                            handleInputUpdate = {handlePBGSearchInputUpdate}
                            
                            setInputFocus = {setPBGSearchInputFocus}
                            
                            setInputValue = {setPBGSearchInputValue}
                            
                            inputFocus = {pbgSearchInputFocus}
                            
                            inputValue = {pbgSearchInputValue}
                       />
                       
                       <div
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                 borderRadius : "0.569rem",
                                 background : "rgba(147, 147, 147, 1)",
                                 height : "0.169rem",
                                 width : "calc(91.69%)",
                                 marginBottom : "1.69rem",
                                 marginTop : "1.069rem",
                            }}
                       />
                       
                       <div
                            className = "invisa-scroller"
                            
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 overflowY : "auto",
                                 boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                 borderRadius : "0.569rem",
                                 background : "rgba(0, 0, 0, 0.069)",
                                 height : "82.69vh",
                                 width : "calc(91.69%)",
                                 paddingBottom : "0.69rem",
                                 paddingRight : "0.369rem",
                                 paddingLeft : "0.369rem",
                                 paddingTop : "0.369rem",
                                 marginRight : "0.69rem",
                                 marginLeft : "0.69rem",
                            }}
                       >
                            {currentPBGCells && currentPBGCells.length > 0 ? (
                                 <>
                                 {currentPBGCells.map((pbgCell, index) => (
                                 <PBGViewCell
                                   key = {index}
                                   
                                   pbgViewCellContainerConfigurationSettings = {{
                                        height : "269px",
                                        width : "328px",
                                        marginBottom : "1.69rem"
                                   }}
                                   
                                   displayName = {`${pbgCell.pbgDisplayName} (${pbgCell.serialK})`}
                                   
                                   notificationViewHeaderTitle = {`${pbgCell.user.displayName} : ${pbgCell.user.accessLevel.accountAccessLevelP}`}
                                   
                                   notificationViewFooterTitle = {pbgCell.pbgStatus}
                                   
                                   pbgViewerHeight = "184.69px"
                                   
                                   pbgViewerWidth = "328px"
                                   
                                   pbgViewSectionTitle = {`${pbgCell.locality.county}, ${pbgCell.locality.state}`}
                                   
                                   pbgViewStatus = {pbgCell.pbgStatus}
                                   
                                   pbgSignalSRC = {pbgCell.pbgSignalSRC}
                                   
                                   pbgViewSRC = {pbgCell.pbgViewSRC}
                                   
                                   pbgViewData = {pbgCell}
                                   
                                   muted = {true}
                                   
                                   volume = {0}
                                   
                                   addToSessionCache = {addToSessionCache}
                                 />
                                 ))}
                                 </>
                            ) : (
                                 <label
                                      style = {{
                                           position : "relative",
                                           flexShrink : 0,
                                           fontFamily : "ModeNine",
                                           fontWeight : "bolder",
                                           fontSize : "1.269rem",
                                           color : "#A1A1A1",
                                           transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                                           marginBottom : "1.69rem",
                                           marginTop : "1.69rem"
                                      }}
                                 >
                                      <HUDTyper speed = {5.69} >
                                           PatrolBuddy(s) not found.
                                      </HUDTyper>
                                 </label>
                            )}
                       </div>
                       </div>
                  ) : (
                       <>
                       </>
                  )}
               </HUDSideContainerPanel>
               
               <label
                    style = {{
                         position : "relative",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                         color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "3.69rem",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setPatrolBuddyGoTitleHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setPatrolBuddyGoTitleHover(true);
                    }}
               >
                    <HUDTyper speed = {processIndicatorTypeSpeed} >
                         {processIndicatorTitle}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.269rem",
                         width : "calc(87%)",
                         background : "#333333",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoGlobalViewPanel;