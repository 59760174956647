import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoSupportChatViewPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Support");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [downloading, setDownloading] = useState(false);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [currentOnGoingChatHover, setCurrentOnGoingChatHover] = useState(false);
     
     const [currentOnGoingChat, setCurrentOnGoingChat] = useState(null);
     
     const [currentActionTraces, setCurrentActionTraces] = useState([]);
     
     const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const [messageViewScrollPosition, setMessageViewScrollPosition] = useState(0);
     
     const [messageInputInFocus, setMessageInputInFocus] = useState(false);
     
     const [messageInputHeight, setMessageInputHeight] = useState(0);
     
     const [messageInputValue, setMessageInputValue] = useState("");
     
     const [messageLineCount, setMessageLineCount] = useState(1);
     
     const [ownMessageBackgroundColor, setOwnMessageBackgroundColor] = useState("rgba(57, 57, 57, 0.569)");
     
     const [messageBackgroundColor, setMessageBackgroundColor] = useState("rgba(31, 31, 31, 0.469)");
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const mostRecentOwnMessageRef = useRef(null);
     
     const mostRecentMessageRef = useRef(null);
     
     const sendMessageButtonRef = useRef(null);
     
     const messageHotBarRef = useRef(null);
     
     const messageInputRef = useRef(null);
     
     const messageViewRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, true);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("", true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     const currentOnGoingSupportChatData = localStorage.getItem("CurrentSupportChat");
     
     const currentSupportChatData = JSON.parse(currentOnGoingSupportChatData);
     
     const [currentChatParticipantsData, setCurrentChatParticipantsData] = useState([]);
     
     const [currentChatMessagesData, setCurrentChatMessagesData] = useState([]);
     
     
     
     
     
     const adjustMessageInputHeight = () => {
          const textArea = messageInputRef.current;
          
          if (!textArea) { return; }
          
          textArea.style.transition = 'none';
          
          textArea.style.height = `${2.969}rem`;
          
          const scrollHeight = textArea.scrollHeight;
          
          if (textArea.scrollHeight < textArea.clientHeight) { return; }
          
          if (messageLineCount <= 5 && messageInputValue.length > 0)
          {
               textArea.style.height = `${scrollHeight}px`;
          }
          
          textArea.style.transition = '';
          
          return;
     };
     
     const handleMessageInputInFocus = (e) => {
          setMessageInputInFocus(true);
          
          return;
     };
     
     const handleMessageInputBlur = (e) => {
          setMessageInputInFocus(false);
          
          return;
     };
     
     const handleMessageInputChange = (e) => {
          setMessageInputValue(e.target.value);
          
          adjustMessageInputHeight();
          
          return;
     };
     
     const broadcastMessageByEnterPress = (e) => {
          if (e.key === "Enter" && !e.shiftKey)
          {
               e.preventDefault();
               
               setPatrolBuddyGoActionButtonHover(true);
               
               setTimeout(() => {
                    broadcastMessage();
                    
                    return;
               }, 369);
               
               return;
          }
          
          return;
     };
     
     const broadcastMessage = () => {
          const currentUTCDateTime = new Date().toUTCString();
          
          const currentDate = new Date(currentUTCDateTime).toLocaleDateString(undefined, {
               year : "2-digit",
               month : "numeric",
               day : "numeric",
          }) +
          ' ' +
          new Date(currentUTCDateTime).toLocaleTimeString(undefined, {
               hour : "numeric",
               minute : "numeric",
               hour12 : true,
          });
          
          const messageData = {
               MessageID : currentChatMessagesData.length,
               MessageDate : currentDate,
               SentMessage : messageInputValue,
               MessageSender : signedUser.displayName,
          };
          
          const patrolBuddyGoSupportNetworkAccessMessageSender = PatrolBuddyGoHUDUtilityManager.GetPatrolBuddyGoSupportNetworkAccess(8699, "supportP", () => {
          const supportNetworkSendMessage = setTimeout(() => {
               const currentUTCTimeInMilliseconds = new Date(currentUTCDateTime).getTime();
               
               const currentUTCTimeInSeconds = currentUTCTimeInMilliseconds / 1000;
               
               var patrolBuddyGoSupportNetworkSendMessageAction = {
                    userID : signedUser.userID,
                    username : signedUser.username,
                    access : signedUser.accessLevel,
                    action : "sendMessageInSupportChat",
                    sentData : {...messageData, DisplayName : signedUser.displayName, ChatID : currentSupportChatData.ChatID, CurrentParticipants : currentSupportChatData.Participants, Date : currentUTCTimeInSeconds}
               };
               
               PatrolBuddyGoHUDUtilityManager.SendDataThroughPatrolBuddyGoSupportNetwork(patrolBuddyGoSupportNetworkAccessMessageSender, patrolBuddyGoSupportNetworkSendMessageAction);
          }, 100);
          
          setCurrentChatMessagesData([...currentChatMessagesData, messageData]);
          
          addToSessionCache("CurrentChatMessages", currentChatMessagesData);
          
          setPatrolBuddyGoActionButtonHover(false);
          
          setMessageInputValue("");
          
          return () => {clearInterval(supportNetworkSendMessage);};
          });
          
          
          
          return () => {
               patrolBuddyGoSupportNetworkAccessMessageSender.close();
          };
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (currentChatParticipantsData.length <= 0)
          {
               setCurrentChatParticipantsData([...currentChatParticipantsData, ...currentSupportChatData.Participants]);
          }
          
          if (sessionCache["CurrentChatMessages"])
          {
               setCurrentChatMessagesData([...currentChatMessagesData, ...sessionCache["CurrentChatMessages"]]);
          }
          
          if (signedUser === undefined || Object.keys(signedUser).length <= 0) { return () => {}; }
          
          const patrolBuddyGoSupportNetworkAccess = PatrolBuddyGoHUDUtilityManager.GetPatrolBuddyGoSupportNetworkAccess(8699, "supportP", () => {
          const supportNetworkMostRecentMessageUpdate = setTimeout(() => {
               var patrolBuddyGoSupportNetworkAction = {
                    userID : signedUser.userID,
                    username : signedUser.username,
                    access : signedUser.accessLevel,
                    action : "getMostRecentMessageInSupportChat",
                    currentChatID : currentSupportChatData.ChatID
               };
               
               var patrolBuddyGoSupportNetworkParticipantsAction = {
                    userID : signedUser.userID,
                    username : signedUser.username,
                    access : signedUser.accessLevel,
                    action : "getCurrentChatParticipants",
                    currentChatID : currentSupportChatData.ChatID
               };
               
               PatrolBuddyGoHUDUtilityManager.SendDataThroughPatrolBuddyGoSupportNetwork(patrolBuddyGoSupportNetworkAccess, patrolBuddyGoSupportNetworkAction);
               
               PatrolBuddyGoHUDUtilityManager.SendDataThroughPatrolBuddyGoSupportNetwork(patrolBuddyGoSupportNetworkAccess, patrolBuddyGoSupportNetworkParticipantsAction);
          }, 100);
          
          
          
          patrolBuddyGoSupportNetworkAccess.onmessage = (event) => {
               const messageEventData = JSON.parse(event.data);
               
               var eventReceivers = {
                   "mostRecentMessage" : function()
                   {
                        const containsMessage = currentChatMessagesData.some(message => message.MessageID === messageEventData.data.MessageID);
                        
                        if (!containsMessage && currentChatMessagesData.length > 0)
                        {
                             setCurrentChatMessagesData([...currentChatMessagesData, messageEventData.data]);
                        }
                        
                        addToSessionCache("CurrentChatMessages", currentChatMessagesData);
                   },
                   
                   "chatParticipants" : function()
                   {
                        setCurrentChatParticipantsData([...messageEventData.data]);
                   }
               };
               
               if (eventReceivers[messageEventData.type])
               {
                    eventReceivers[messageEventData.type]();
               }
               
               return;
          };
          
          return () => {clearInterval(supportNetworkMostRecentMessageUpdate);};
          });
          
          return () => {
               patrolBuddyGoSupportNetworkAccess.close();
          };
     }, [signedUser]);
     
     useEffect(() => {
          const timer = setTimeout(() => {
               setCurrentChatMessagesData(currentSupportChatData.CurrentMessages);
               
               const getCurrentActionTraces = async () => { 
                    if (signedUser.userID !== null && signedUser.userID !== undefined)
                    {
                         const userActionTraces = await PatrolBuddyGoHUDUtilityManager.GetPatrolBuddyGoUserActionTraces(signedUser.userID);
                         
                         setCurrentActionTraces(userActionTraces);
                         
                         return;
                    }
               };
               
               getCurrentActionTraces();
               
               if (messageInputRef.current === null || messageInputRef.current === undefined) { return; }
               
               messageInputRef.current.focus();
               
               return;
          }, 100);
          
          return () => clearInterval(timer);
     }, []);
     
     useEffect(() => {
          if (messageInputRef.current === null || messageInputRef.current === undefined) { return; }
          
          if (sidePanelVisible)
          {
               messageInputRef.current.blur();
               
               return;
          }
          
          messageInputRef.current.focus();
          
          return;
     }, [sidePanelVisible]);
     
     useEffect(() => {
          const lineCount = messageInputValue.split('\n').length;
          
          setMessageLineCount(lineCount);
          
          adjustMessageInputHeight();
          
          return () => {};
     }, [messageInputValue]);
     
     useEffect(() => {
          if (messageViewRef.current === null || messageViewRef.current === undefined) { return; }
          
          setMessageViewScrollPosition(messageViewRef.current.scrollHeight - messageViewRef.current.clientHeight);
          
          if (mostRecentOwnMessageRef.current !== null && currentChatMessagesData.length > 0 && currentChatMessagesData[currentChatMessagesData.length - 1].MessageSender === signedUser.displayName) {
               const mostRecentOwnMessageCell = mostRecentOwnMessageRef.current;
               
               const mostRecentOwnMessageCellPosition = mostRecentOwnMessageCell.getBoundingClientRect().top + window.pageYOffset;
               
               const messageView = messageViewRef.current;
               
               const timer = setTimeout(() => {
                    messageView.scrollTop = messageView.scrollHeight - messageView.clientHeight;
                    
                    window.scrollTo({ top: mostRecentOwnMessageCellPosition, behavior: 'smooth' });
               }, 269);
               
               return () => clearInterval(timer);
          }
          
          if (currentChatMessagesData.length > 0 && currentChatMessagesData[currentChatMessagesData.length - 1].MessageSender !== signedUser.displayName) {
               setMessageBackgroundColor("#9FD1D9");
               
               const timer = setTimeout(() => {
                   setMessageBackgroundColor("rgba(31, 31, 31, 0.469)");
                   
                   return;
               }, 369);
               
               return () => clearInterval(timer);
          }
     }, [currentChatMessagesData]);
     
     useEffect(() => {
          if (downloading === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return () => {};
     }, [downloading]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <HUDSidePanel
                    sidePanelRef = {sidePanelRef}
                    
                    sidePanelMinimizeTabButtonConfigurationSettings = {{
                         background : "transparent",
                         color : "#A1A1A1"
                    }}
                    
                    sidePanelButtonUnderlineConfigurationSettings = {{
                         background : "linear-gradient(to right, #A1A1A1, white)"
                    }}
                    
                    sidePanelHeaderLogoConfigurationSettings = {{
                         transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                         scale : sidePanelLogoHover ? "1.069" : "1",
                         cursor : "pointer",
                         marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                    }}
                    
                    sidePanelContainerConfigurationSettings = {{
                         background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                    }}
                    
                    sidePanelTopHeaderConfigurationSettings = {{
                         marginTop : "0.69rem"
                    }}
                    
                    sidePanelTopHeaderContents = {signedIn && signedUser.accessLevel && signedUser.accessLevel.accountAccessR > 0 ? [
                         <HUDIcon 
                              iconConfigurationSettings = {{
                                   transition : "scale 0.3s ease",
                                   scale : backButtonHover ? "1.069" : "1",
                                   cursor : "pointer"
                              }}
                              
                              name = "fa-chevron-left"
                              
                              color = "#A1A1A1"
                              
                              onMouseLeave = {() => {
                                   setBackButtonHover(false);
                                   
                                   return;
                              }}
                              
                              onMouseEnter = {() => {
                                   setBackButtonHover(true);
                                   
                                   return;
                              }}
                              
                              onClick = {() => {
                                   pbgPanelNavigator(-1);
                                   
                                   return;
                              }}
                         />
                    ] : []}
                    
                    sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
                    
                    sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverEnterAction = {() => {
                         setSidePanelLogoHover(true);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverExitAction = {() => {
                         setSidePanelLogoHover(false);
                         
                         return;
                    }}
                    
                    sidePanelButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoAction = {() => {
                         window.open("https://generalizedrobotics.com", "_blank")
                         
                         return;
                    }}
                    
                    logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                    
                    sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                    
                    sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                    
                    minimizeTabPosition = "center-right"
                    
                    hideType = "Full"
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
               />
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.269s ease-in",
                         width : "calc(94.69%)",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : "rgba(12.69, 12.69, 12.69, 0.569)",
                         marginBottom : "1rem",
                         marginTop : "2.69rem",
                    }}
               >
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         justifyContent : "center",
                         height : "auto",
                         transition : "height 0.269s ease-in",
                         width : "calc(91.69%)",
                         borderRadius : "1rem",
                         background : "transparent",
                         marginBottom : "1rem",
                         marginLeft : "1.269rem",
                         marginTop : "0.869rem",
                    }}
               >
                    <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "flex-start",
                         height : "auto",
                         transition : "height 0.269s ease-in",
                         width : "calc(100%)",
                         borderRadius : "1rem",
                         background : "transparent",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.69rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.ChatTitle}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "center",
                              wordWrap : "break-word",
                              fontSize : "1.169rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.ChatCreationDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.169rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.ChatStatus}
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              height : "0.169rem",
                              width : "calc(100%)",
                              background : "#AAAAAA",
                              marginTop : "1.469rem",
                         }}
                    >
                         
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.269rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              Current Participants:
                         </HUDTyper>
                    </label>
                    
                    {currentChatParticipantsData.length > 0 ? (
                    <>
                         <div
                              className = "invisa-scroller"
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   height : "7.269rem",
                                   transition : "height 0.269s ease-in",
                                   width : "calc(98.69%)",
                                   overflowY : "scroll",
                                   overflowX : "hidden",
                                   borderRadius : "1rem",
                                   boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                   background : "rgba(0, 0, 0, 0.269)",
                                   marginBottom : "1rem",
                                   marginTop : "1.269rem",
                              }}
                         >
                         {currentChatParticipantsData.map((currentChatParticipantData, index) => (
                         <div
                              className = "invisa-scroller"
                              
                              key = {index}
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "column",
                                   alignItems : "center",
                                   height : "calc(100%)",
                                   transition : "height 0.269s ease-in",
                                   width : "calc(15.69%)",
                                   overflowY : "scroll",
                                   overflowX : "hidden",
                                   borderRadius : "1rem",
                                   boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                   background : "rgba(0, 0, 0, 0.269)",
                                   marginRight : "0.269rem",
                                   gap : "0.269rem",
                              }}
                         >
                              <img
                                    style = {{
                                         height : "4rem",
                                         width : "4rem",
                                         marginTop : "0.69rem",
                                    }}
                                    
                                    src = {currentChatParticipantData.AccessP >= 2 ? require("../HUDContent/PatrolBuddyChiefOfPatrolProfilePicHonorIcon.png") : require("../HUDContent/PatrolBuddyProfilePicHonorIcon.png")}
                              />
                              
                              <label
                                   style = {{
                                        position : "relative",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bolder",
                                        textAlign : "center",
                                        wordWrap : "break-word",
                                        fontSize : "0.69rem",
                                        color : "#A5A5A5",
                                        width : "calc(100%)",
                                        transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                        marginTop : "0.469rem",
                                        flexShrink : 0
                                   }}
                              >
                                   <HUDTyper speed = {7} >
                                        {currentChatParticipantData.DisplayName}
                                   </HUDTyper>
                              </label>
                         </div>
                         ))}
                         </div>
                    </>
                    ) : (
                         <>
                              
                         </>
                    )}
               </div>
               
               <div
                    className = "invisa-scroller"
                    
                    ref = {messageViewRef}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         height : "35.269rem",
                         transition : "height 0.269s ease-in",
                         width : "calc(98.69%)",
                         overflowY : "scroll",
                         overflowX : "hidden",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : "rgba(0, 0, 0, 0.269)",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
               >
               {currentChatMessagesData.length > 0 && currentChatMessagesData.map((currentChatMessageData, index) => (
               <Fade direction = {currentChatMessageData.MessageSender === signedUser.displayName ? "right" : "left"} duration = {369} >
               <div
                    key = {index}
                    
                    ref = {index === currentChatMessagesData.length - 1 && currentChatMessageData.MessageSender === signedUser.displayName ? mostRecentOwnMessageRef : mostRecentMessageRef}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         height : "auto",
                         transition : "height 0.269s ease-in, background 0.69s ease-in-out, scale 0.369s ease-in, box-shadow 0.569s ease-in",
                         boxShadow : currentChatMessageData === index ? "inset 0 0 10px rgba(0, 0, 0, 0.69)" : "3px 3px 9.69px -2.69px rgba(0, 0, 0, 0.469)",
                         scale : currentChatMessageData === index ? "1.10069" : "1",
                         width : "calc(47%)",
                         borderRadius : "1rem",
                         background : currentChatMessageData.MessageSender === signedUser.displayName ? ownMessageBackgroundColor : messageBackgroundColor,
                         marginLeft : currentChatMessageData.MessageSender === signedUser.displayName ? "auto" : "0.69rem",
                         marginRight : "0.69rem",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    
                    onMouseLeave = {() => {
                         setCurrentOnGoingChatHover(false);
                         
                         setCurrentOnGoingChat(null);
                    }}
                    
                    onMouseEnter = {() => {
                         setCurrentOnGoingChatHover(true);
                         
                         setCurrentOnGoingChat(index);
                    }}
               >
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              transition : "height 0.269s ease-in",
                              width : "calc(87%)",
                         }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentChatMessageData.MessageSender}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "right",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : "#A5A5A5",
                              width : "calc(48.069%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginTop : "0.969rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentChatMessageData.MessageDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    <pre
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bold",
                              textAlign : "left",
                              whiteSpace : "pre-wrap", 
                              wordWrap : "break-word",
                              fontSize : "0.9169rem",
                              color : "#A1A1A1",
                              width : "calc(85.69%)",
                              overflowY : "hidden",
                              overflowX : "hidden",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginBottom : "2.269rem",
                              marginLeft : "2.269rem",
                              marginTop : "1.069rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {3} >
                              {currentChatMessageData.SentMessage}
                         </HUDTyper>
                    </pre>
               </div>
               </Fade>
               ))}
               </div>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         alignItems : "center",
                         height : "7.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.0969)",
                         visibility : downloading ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         overflowY : "auto",
                         zIndex : "69",
                         bottom : 0
                    }}
               >
                    <textarea
                         className = "hud-input-light"
                         
                         type = "text"
                         
                         ref = {messageInputRef}
                         
                         value = {messageInputValue}
                         
                         onChange = {handleMessageInputChange}
                         
                         onFocus = {handleMessageInputInFocus}
                         
                         onBlur = {handleMessageInputBlur}
                         
                         rows = {messageLineCount}
                         
                         placeholder = "Type your message..."
                         
                         style = {{ 
                              position : "relative",
                              display : "flex",
                              overflowX : "hidden",
                              overflowY : "auto",
                              outline : "none",
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, margin-left 0.369s ease",
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : messageInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                              borderBottom : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              background : "transparent",
                              borderRadius : "2rem",
                              color : messageInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              scale : messageInputInFocus ? "1.069" : "1",
                              maxHeight : "5.69rem",
                              height : "2.969rem",
                              width : "calc(47%)",
                              resize : "none",
                              paddingBottom : "0.469rem",
                              paddingRight : "0.769rem",
                              paddingLeft : "0.769rem",
                              paddingTop : "0.69rem",
                              marginBottom : "0.69rem",
                              marginLeft : messageInputInFocus ? "5.69rem" : "2.169rem",
                              marginTop : "1.269rem"
                         }}
                         
                         onKeyPress = {broadcastMessageByEnterPress}
                    />
                    
                    {messageInputValue.trim() !== "" && messageInputValue !== null && (
                    <div
                         ref = {sendMessageButtonRef}
                         
                         style = {{
                              position : "fixed",
                              display : "flex",
                              flexDirection : "row",
                              alignItems : "center",
                              height : "2.469rem",
                              width : "auto",
                              scale : patrolBuddyGoActionButtonHover ? "1.169" : "1",
                              transition : "scale 0.3s ease-in-out, background 0.69s ease-in-out, box-shadow 0.69s ease-in-out",
                              background : patrolBuddyGoActionButtonHover ? "#9FD1D9" : "#575757",
                              borderRadius : "1.269rem",
                              boxShadow : patrolBuddyGoActionButtonHover ? "0 0 10px rgba(255, 255, 255, 0.69)" : "inset 0 0 10px rgba(0, 0, 0, 0.69)",
                              marginBottom : "10.69rem",
                              marginLeft : messageInputInFocus ? "5.69rem" : "1.969rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoActionButtonHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoActionButtonHover(true);
                         }}
                         
                         onClick = {async () => {
                              broadcastMessage();
                              
                              return;
                         }}
                    >
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   fontSize : "1.469rem",
                                   color : "#FFFFFF",
                                   marginRight : "1.69rem",
                                   marginLeft : "1.69rem",
                                   flexShrink : 0
                              }}
                          >
                              <HUDTyper speed = {2} >
                                   Send
                              </HUDTyper>
                          </label>
                    </div>
                    )}
               </div>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "0rem",
                    }}
               >
                    
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoSupportChatViewPanel;