import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoControlPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyGoActionButton from "../HUDComponents/PatrolBuddyGoActionButton.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoAccountCreationPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [processing, setProcessing] = useState(false);
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Enter Precinct Address");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [baseAccountCreationPanelVisible, setBaseAccountCreationPanelVisible] = useState(false);
     
     const [pbgAccountCreationPanelVisible, setPBGAccountCreationPanelVisible] = useState(false);
     
     const [accountCredentialsPanelVisible, setAccountCredentialsPanelVisible] = useState(false);
     
     const [precinctAddressPanelVisible, setPrecinctAddressPanelVisible] = useState(false);
     
     const [accountTypePanelVisible, setAccountTypePanelVisible] = useState(true);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     
     
     const [currentAccountTypeHover, setCurrentAccountTypeHover] = useState(false);
     
     const [currentAccountTypeIndex, setCurrentAccountTypeIndex] = useState(null);
     
     
     
     const [addressInputInFocus, setAddressInputInFocus] = useState(false);
     
     const [addressInputValue, setAddressInputValue] = useState("");
     
     const [currentLocality, setCurrentLocality] = useState({});
     
     const [selectedPBGUserRank, setSelectedPBGUserRank] = useState(0);
     
     const [pbgUserRanks, setPBGUserRanks] = useState([]);
     
     
     
     const [firstNameInputInFocus, setFirstNameInputInFocus] = useState(false);
     
     const [middleNameInputInFocus, setMiddleNameInputInFocus] = useState(false);
     
     const [lastNameInputInFocus, setLastNameInputInFocus] = useState(false);
     
     const [usernameInputInFocus, setUsernameInputInFocus] = useState(false);
     
     const [emailInputInFocus, setEmailInputInFocus] = useState(false);
     
     const [passwordInputInFocus, setPasswordInputInFocus] = useState(false);
     
     const [firstNameInputValue, setFirstNameInputValue] = useState("");
     
     const [middleNameInputValue, setMiddleNameInputValue] = useState("");
     
     const [lastNameInputValue, setLastNameInputValue] = useState("");
     
     const [usernameInputValue, setUsernameInputValue] = useState("");
     
     const [emailInputValue, setEmailInputValue] = useState("");
     
     const [passwordInputValue, setPasswordInputValue] = useState("");
     
     
     
     const addressInputRef = useRef(null);
     
     
     
     const firstNameInputRef = useRef(null);
     
     const middleNameInputRef = useRef(null);
     
     const lastNameInputRef = useRef(null);
     
     const usernameInputRef = useRef(null);
     
     const emailInputRef = useRef(null);
     
     const passwordInputRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("Add Accounts");
     
     const patrolBuddyGoAccountTypes = [
          {
               id : 1,
               title : "Base Account",
               restrictedAccessR : 3,
               hudAction : () => {
                    setBaseAccountCreationPanelVisible(true);
                    
                    setPrecinctAddressPanelVisible(true);
                    
                    setAccountTypePanelVisible(false);
                    
                    setProcessIndicatorTitle("Enter Precinct Address");
                    
                    return;
               }
          },
          {
               id : 2,
               title : "PatrolBuddyGo User",
               restrictedAccessR : 2,
               hudAction : () => {
                    setBaseAccountCreationPanelVisible(false);
                    
                    setPBGAccountCreationPanelVisible(true);
                    
                    setPrecinctAddressPanelVisible(false);
                    
                    setAccountTypePanelVisible(false);
                    
                    setProcessIndicatorTitle("Enter PatrolBuddyGo User Credentials");
                    
                    return;
               }
          }
     ];
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     const handleAddressInputInFocus = (e) => {
          setAddressInputInFocus(true);
          
          return;
     };
     
     const handleAddressInputBlur = (e) => {
          setAddressInputInFocus(false);
          
          return;
     };
     
     const handleAddressInputChange = (e) => {
          setAddressInputValue(e.target.value);
          
          return;
     };
     
     
     
     const handleFirstNameInputInFocus = (e) => {
          setFirstNameInputInFocus(true);
          
          return;
     };
     
     const handleFirstNameInputBlur = (e) => {
          setFirstNameInputInFocus(false);
          
          return;
     };
     
     const handleMiddleNameInputInFocus = (e) => {
          setMiddleNameInputInFocus(true);
          
          return;
     };
     
     const handleMiddleNameInputBlur = (e) => {
          setMiddleNameInputInFocus(false);
          
          return;
     };
     
     const handleLastNameInputInFocus = (e) => {
          setLastNameInputInFocus(true);
          
          return;
     };
     
     const handleLastNameInputBlur = (e) => {
          setLastNameInputInFocus(false);
          
          return;
     };
     
     const handleUsernameInputInFocus = (e) => {
          setUsernameInputInFocus(true);
          
          return;
     };
     
     const handleUsernameInputBlur = (e) => {
          setUsernameInputInFocus(false);
          
          return;
     };
     
     const handleEmailInputInFocus = (e) => {
          setEmailInputInFocus(true);
          
          return;
     };
     
     const handleEmailInputBlur = (e) => {
          setEmailInputInFocus(false);
          
          return;
     };
     
     const handlePasswordInputInFocus = (e) => {
          setPasswordInputInFocus(true);
          
          return;
     };
     
     const handlePasswordInputBlur = (e) => {
          setPasswordInputInFocus(false);
          
          return;
     };
     
     const handleFirstNameInputChange = (e) => {
          setFirstNameInputValue(e.target.value);
          
          return;
     };
     
     const handleMiddleNameInputChange = (e) => {
          setMiddleNameInputValue(e.target.value);
          
          return;
     };
     
     const handleLastNameInputChange = (e) => {
          setLastNameInputValue(e.target.value);
          
          return;
     };
     
     const handleUsernameInputChange = (e) => {
          setUsernameInputValue(e.target.value);
          
          return;
     };
     
     const handleEmailInputChange = (e) => {
          setEmailInputValue(e.target.value);
          
          return;
     };
     
     const handlePasswordInputChange = (e) => {
          setPasswordInputValue(e.target.value);
          
          return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     async function HandleLocality()
     {
          setProcessIndicatorTitle("Verifying Address...");
          
          setProcessIndicatorTypeSpeed(21);
          
          setProcessing(true);
          
          const addresses = await PatrolBuddyGoHUDUtilityManager.GetAddresses(addressInputValue);
          
          if (addresses === null) 
          { 
                setTimeout(() => {
                     setProcessIndicatorTitle("Try Again...");
                     
                     setProcessIndicatorColor("#616161");
                     
                     setProcessing(false);
                }, 3000);
                
                setTimeout(() => {
                     setProcessIndicatorTypeSpeed(3);
                }, 3000);
                
                return; 
          }
          
          const desiredAddressData = addresses[0];
          
          const desiredAddress = desiredAddressData["formatted_address"];
          
          setProcessIndicatorTitle("Getting Locality...!");
          
          const addedLocality = await PatrolBuddyGoHUDUtilityManager.AddPBGLocality(desiredAddress);
          
          if (addedLocality !== null)
          {
               setCurrentLocality(addedLocality);
               
               setTimeout(() => {
                    setProcessIndicatorTitle("Address Verified!");
                    
                    setProcessIndicatorColor("#616161");
                    
                    setProcessing(false);
               }, 3000);
               
               setTimeout(() => {
                    setAddressInputValue("");
                    
                    setPrecinctAddressPanelVisible(false);
                    
                    setAccountCredentialsPanelVisible(true);
                    
                    setProcessIndicatorTitle("Enter County Account Credentials");
                    
                    setProcessIndicatorColor("#616161");
                    
                    setProcessIndicatorTypeSpeed(3);
               }, 3000);
               
               return;
          }
          
          setTimeout(() => {
               setProcessIndicatorTitle("Try Again...");
               
               setProcessIndicatorColor("#616161");
               
               setProcessing(false);
          }, 3000);
          
          setTimeout(() => {
               setProcessIndicatorTypeSpeed(3);
          }, 3000);
          
          return;
     };
     
     
     
     
     
     async function HandleBaseAccountCreation() 
     {
          setProcessIndicatorTitle("Creating Account...");
          
          setProcessIndicatorTypeSpeed(5);
          
          setProcessing(true);
          
          const authenticated = await PatrolBuddyGoHUDUtilityManager.AddPBGUserBaseAccount(usernameInputValue, passwordInputValue, currentLocality.county);
          
          setTimeout(() => {
               if (authenticated === true)
               {
                    setProcessIndicatorTitle("Account Creation Successful!");
               }
               else
               {
                   setProcessIndicatorTitle("Try Again...");
               }
               
               setProcessIndicatorColor("#616161");
               
               setProcessing(false);
          }, 3000);
          
          setTimeout(() => {
               PatrolBuddyGoControlPanelNavigationActivation(pbgPanelNavigator);
               
               setProcessIndicatorTypeSpeed(3);
          }, 3000);
          
          return;
     };
     
     async function HandleAccountCreation() 
     {
          setProcessIndicatorTitle("Creating PBG Account...");
          
          setProcessIndicatorTypeSpeed(5);
          
          setProcessing(true);
          
          const selectedRank = pbgUserRanks[selectedPBGUserRank];
          
          const authenticated = await PatrolBuddyGoHUDUtilityManager.AddPBGUserAccount(firstNameInputValue, middleNameInputValue ? middleNameInputValue : null, lastNameInputValue, usernameInputValue, emailInputValue, passwordInputValue, selectedRank, signedUser.locality ? signedUser.locality.county : null);
          
          setTimeout(() => {
               if (authenticated === true)
               {
                    setProcessIndicatorTitle("PBG Account Creation Successful!");
               }
               else
               {
                   setProcessIndicatorTitle("Try Again...");
               }
               
               setProcessIndicatorColor("#616161");
               
               setProcessing(false);
          }, 3000);
          
          setTimeout(() => {
               PatrolBuddyGoControlPanelNavigationActivation(pbgPanelNavigator);
               
               setProcessIndicatorTypeSpeed(3);
          }, 3000);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (processing === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return;
     }, [processing]);
     
     useEffect(() => {
          if (precinctAddressPanelVisible === true) 
          {
               setProcessIndicatorTitle("Enter Precinct Address");
               
               return () => {};
          }
          
          return;
     }, [precinctAddressPanelVisible]);
     
     useEffect(() => {
          if (accountTypePanelVisible === true) 
          {
               setProcessIndicatorTitle("Select Account Type");
               
               return () => {};
          }
          
          return;
     }, [accountTypePanelVisible]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return;
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
                    <>
                         <HUDSidePanel
                              sidePanelRef = {sidePanelRef}
                              
                              sidePanelMinimizeTabButtonConfigurationSettings = {{
                                   background : "transparent",
                                   color : "#A1A1A1"
                              }}
                              
                              sidePanelButtonUnderlineConfigurationSettings = {{
                                   background : "linear-gradient(to right, #A1A1A1, white)"
                              }}
                              
                              sidePanelHeaderLogoConfigurationSettings = {{
                                   transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                                   scale : sidePanelLogoHover ? "1.069" : "1",
                                   cursor : "pointer",
                                   marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                              }}
                              
                              sidePanelContainerConfigurationSettings = {{
                                   background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                              }}
                              
                              sidePanelTopHeaderConfigurationSettings = {{
                                   marginTop : "0.69rem"
                              }}
                              
                              sidePanelTopHeaderContents = {signedIn && signedUser.accessLevel && signedUser.accessLevel.accountAccessR > 0 && (baseAccountCreationPanelVisible || pbgAccountCreationPanelVisible) ? [
                                   <HUDIcon 
                                        iconConfigurationSettings = {{
                                             transition : "scale 0.3s ease",
                                             scale : backButtonHover ? "1.069" : "1",
                                             cursor : "pointer"
                                        }}
                                        
                                        name = "fa-chevron-left"
                                        
                                        color = "#A1A1A1"
                                        
                                        onMouseLeave = {() => {
                                             setBackButtonHover(false);
                                             
                                             return;
                                        }}
                                        
                                        onMouseEnter = {() => {
                                             setBackButtonHover(true);
                                             
                                             return;
                                        }}
                                        
                                        onClick = {() => {
                                             if (accountTypePanelVisible === true)
                                             {
                                                  pbgPanelNavigator(-1);
                                                  
                                                  return;
                                             }
                                             
                                             if (baseAccountCreationPanelVisible && precinctAddressPanelVisible === true)
                                             {
                                                  setBaseAccountCreationPanelVisible(false);
                                                  
                                                  setPrecinctAddressPanelVisible(false);
                                                  
                                                  setAccountTypePanelVisible(true);
                                                  
                                                  setCurrentAccountTypeHover(false);
                                                  
                                                  setCurrentAccountTypeIndex(null);
                                                  
                                                  setUsernameInputValue("");
                                                  
                                                  setPasswordInputValue("");
                                                  
                                                  return;
                                             }
                                             
                                             if (baseAccountCreationPanelVisible && accountCredentialsPanelVisible === true)
                                             {
                                                  setAccountCredentialsPanelVisible(false);
                                                  
                                                  setPrecinctAddressPanelVisible(true);
                                                  
                                                  setAddressInputValue("");
                                                  
                                                  setCurrentLocality({});
                                                  
                                                  return;
                                             }
                                             
                                             if (pbgAccountCreationPanelVisible)
                                             {
                                                  setPBGAccountCreationPanelVisible(false);
                                                  
                                                  setAccountTypePanelVisible(true);
                                                  
                                                  setCurrentAccountTypeHover(false);
                                                  
                                                  setCurrentAccountTypeIndex(null);
                                                  
                                                  return;
                                             }
                                             
                                             return;
                                        }}
                                   />
                              ] : []}
                              
                              sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                              
                              sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
                              
                              sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                                   if (sidePanelButton.hudAction)
                                   {
                                        sidePanelButton.hudAction();
                                        
                                        return;
                                   }
                                   
                                   pbgPanelNavigator(sidePanelButton.navigationID);
                                   
                                   return;
                              }}
                              
                              sidePanelLogoHoverEnterAction = {() => {
                                   setSidePanelLogoHover(true);
                                   
                                   return;
                              }}
                              
                              sidePanelLogoHoverExitAction = {() => {
                                   setSidePanelLogoHover(false);
                                   
                                   return;
                              }}
                              
                              sidePanelButtonAction = {(sidePanelButton, index) => {
                                   if (sidePanelButton.hudAction)
                                   {
                                        sidePanelButton.hudAction();
                                        
                                        return;
                                   }
                                   
                                   pbgPanelNavigator(sidePanelButton.navigationID);
                                   
                                   return;
                              }}
                              
                              sidePanelLogoAction = {() => {
                                   window.open("https://generalizedrobotics.com", "_blank")
                                   
                                   return;
                              }}
                              
                              logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                              
                              sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                              
                              sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                              
                              minimizeTabPosition = "center-right"
                              
                              hideType = "Full"
                              
                              setVisible = {setSidePanelVisible}
                              
                              visible = {sidePanelVisible}
                         />
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   fontSize : patrolBuddyGoTitleHover ? "3.169rem" : "2.969rem",
                                   color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                                   transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "5.469rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   {processIndicatorTitle}
                              </HUDTyper>
                         </label>
                         
                         <div
                              style = {{
                                   position : "relative",
                                   height : "0.469rem",
                                   width : "calc(87%)",
                                   background : "#333333",
                                   marginTop : "1.469rem",
                              }}
                         >
                              
                         </div>
                         
                         {!baseAccountCreationPanelVisible && !pbgAccountCreationPanelVisible && accountTypePanelVisible === true ? (
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "row",
                                        justifyContent : "center",
                                        height : currentAccountTypeHover ? "5.69rem" : "4rem",
                                        transition : "height 0.269s ease-in",
                                        width : "calc(87%)",
                                        borderRadius : "1rem",
                                        background : "#333333",
                                        marginBottom : "1rem",
                                        marginTop : "2.469rem",
                                   }}
                              >
                                   {patrolBuddyGoAccountTypes.length > 0 && patrolBuddyGoAccountTypes.map((patrolBuddyGoAccountType, index) => {
                                        if (patrolBuddyGoAccountType.restrictedAccessR <= signedUser.accessLevel.accountAccessR) 
                                        {
                                             return (
                                                  <div
                                                       key = {index}
                                                       
                                                       style = {{
                                                            position : "relative",
                                                            display : "flex",
                                                            flexDirection : "column",
                                                            alignItems : "center",
                                                            height :  currentAccountTypeIndex === index ? "5rem" : "3.569rem",
                                                            width : "calc(18%)",
                                                            transition : "height 0.369s ease-in-out, box-shadow 0.69s ease-in-out",
                                                            boxShadow : currentAccountTypeIndex === index ? "0 0 20px rgba(0, 0, 0, 0.469)" : "none",
                                                            borderRadius : "1rem",
                                                            background : "rgba(21, 21, 21, 0.369)",
                                                            marginBottom : "0rem",
                                                            marginLeft : "1rem",
                                                            marginTop : "0.169rem",
                                                       }}
                                                       
                                                       onMouseLeave = {() => {
                                                            setCurrentAccountTypeHover(false);
                                                            
                                                            setCurrentAccountTypeIndex(null);
                                                       }}
                                                       
                                                       onMouseEnter = {() => {
                                                            setCurrentAccountTypeHover(true);
                                                            
                                                            setCurrentAccountTypeIndex(index);
                                                       }}
                                                       
                                                       onClick = {patrolBuddyGoAccountType.hudAction}
                                                  >
                                                       <label
                                                            style = {{
                                                                 position : "relative",
                                                                 fontFamily : "ModeNine",
                                                                 fontWeight : "bolder",
                                                                 textAlign : "center",
                                                                 fontSize : currentAccountTypeIndex === index ? "1.469rem" : "1.269rem",
                                                                 color : currentAccountTypeIndex === index ? "#A5A5A5" : "#616161",
                                                                 transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                                                 marginTop : currentAccountTypeIndex === index ? "1.469rem" : "0.569rem",
                                                                 flexShrink : 0
                                                            }}
                                                       >
                                                            <HUDTyper speed = {processIndicatorTypeSpeed} >
                                                                 {patrolBuddyGoAccountType.title}
                                                            </HUDTyper>
                                                       </label>
                                                  </div>
                                             );
                                        }
                                   })}
                              </div>
                         ) : (
                         <>
                         </>
                         )}
                         
                         {baseAccountCreationPanelVisible === true ? (
                         <>
                         {precinctAddressPanelVisible === true ? (
                              <form
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "center",
                                        height : processing ? "0rem" : "16.99rem",
                                        width : "calc(69%)",
                                        transition : "height 0.869s ease-in-out",
                                        boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                                        borderRadius : "1rem",
                                        background : "#333333",
                                        marginBottom : "2rem",
                                        marginTop : "2.469rem",
                                   }}
                              >
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {addressInputRef}
                                        
                                        value = {addressInputValue}
                                        
                                        onChange = {handleAddressInputChange}
                                        
                                        onFocus = {handleAddressInputInFocus}
                                        
                                        onBlur = {handleAddressInputBlur}
                                        
                                        placeholder = "Address..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : addressInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : addressInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : addressInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : addressInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : addressInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                              </form>
                         ) : (
                              <form
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "center",
                                        height : processing ? "0rem" : "16.99rem",
                                        width : "calc(69%)",
                                        transition : "height 0.869s ease-in-out",
                                        boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                                        borderRadius : "1rem",
                                        background : "#333333",
                                        marginBottom : "2rem",
                                        marginTop : "2.469rem",
                                   }}
                              >
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {usernameInputRef}
                                        
                                        value = {usernameInputValue}
                                        
                                        onChange = {handleUsernameInputChange}
                                        
                                        onFocus = {handleUsernameInputInFocus}
                                        
                                        onBlur = {handleUsernameInputBlur}
                                        
                                        placeholder = "Username..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : usernameInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : usernameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : usernameInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "password"
                                        
                                        ref = {passwordInputRef}
                                        
                                        value = {passwordInputValue}
                                        
                                        onChange = {handlePasswordInputChange}
                                        
                                        onFocus = {handlePasswordInputInFocus}
                                        
                                        onBlur = {handlePasswordInputBlur}
                                        
                                        placeholder = "Password..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : passwordInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : passwordInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : passwordInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                              </form>
                         )}
                         </>
                         ) : pbgAccountCreationPanelVisible === true ? (
                              <div
                                    style = {{
                                         position : "relative",
                                         display : "flex",
                                         justifyContent : "center",
                                         alignItems : "center",
                                         width : "calc(100%)",
                                    }}
                              >
                              <form
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "center",
                                        height : processing ? "0rem" : "41.99rem",
                                        width : "calc(69%)",
                                        transition : "height 0.869s ease-in-out",
                                        boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                                        borderRadius : "1rem",
                                        background : "#333333",
                                        marginBottom : "2rem",
                                        marginTop : "2.469rem",
                                   }}
                              >
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {firstNameInputRef}
                                        
                                        value = {firstNameInputValue}
                                        
                                        onChange = {handleFirstNameInputChange}
                                        
                                        onFocus = {handleFirstNameInputInFocus}
                                        
                                        onBlur = {handleFirstNameInputBlur}
                                        
                                        placeholder = "First Name..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : firstNameInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : firstNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : firstNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : firstNameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : firstNameInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {middleNameInputRef}
                                        
                                        value = {middleNameInputValue}
                                        
                                        onChange = {handleMiddleNameInputChange}
                                        
                                        onFocus = {handleMiddleNameInputInFocus}
                                        
                                        onBlur = {handleMiddleNameInputBlur}
                                        
                                        placeholder = "Middle Name..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : middleNameInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : middleNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : middleNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : middleNameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : middleNameInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {lastNameInputRef}
                                        
                                        value = {lastNameInputValue}
                                        
                                        onChange = {handleLastNameInputChange}
                                        
                                        onFocus = {handleLastNameInputInFocus}
                                        
                                        onBlur = {handleLastNameInputBlur}
                                        
                                        placeholder = "Last Name..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : lastNameInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : lastNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : lastNameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : lastNameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : lastNameInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "text"
                                        
                                        ref = {usernameInputRef}
                                        
                                        value = {usernameInputValue}
                                        
                                        onChange = {handleUsernameInputChange}
                                        
                                        onFocus = {handleUsernameInputInFocus}
                                        
                                        onBlur = {handleUsernameInputBlur}
                                        
                                        placeholder = "Username..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : usernameInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : usernameInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : usernameInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : usernameInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "email"
                                        
                                        ref = {emailInputRef}
                                        
                                        value = {emailInputValue}
                                        
                                        onChange = {handleEmailInputChange}
                                        
                                        onFocus = {handleEmailInputInFocus}
                                        
                                        onBlur = {handleEmailInputBlur}
                                        
                                        placeholder = "Email..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : emailInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : emailInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : emailInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : emailInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : emailInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                                   
                                   <input
                                        className = "verification-modal-input-light"
                                        
                                        type = "password"
                                        
                                        ref = {passwordInputRef}
                                        
                                        value = {passwordInputValue}
                                        
                                        onChange = {handlePasswordInputChange}
                                        
                                        onFocus = {handlePasswordInputInFocus}
                                        
                                        onBlur = {handlePasswordInputBlur}
                                        
                                        placeholder = "Password..."
                                        
                                        style = {{ 
                                             background : "transparent",
                                             borderRadius : "2rem",
                                             height : "2.969rem",
                                             outline : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             boxShadow : passwordInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                             borderBottom : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             borderRight : passwordInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                             color : passwordInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                             scale : passwordInputInFocus ? "1.069" : "1",
                                             transition : "scale 0.3s ease-in-out, opacity 0.69s ease-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                             visibility : processing ? "hidden" : "visible",
                                             opacity : processing ? 0 : 1,
                                             fontFamily : "ModeNine",
                                             fontSize : "1.269rem",
                                             width : "calc(57%)",
                                             marginTop : "3rem",
                                        }}
                                   />
                              </form>
                              </div>
                         ) : (
                         <>
                         </>
                         )}
                         
                         
                         <label
                              style = {{
                                   position : "fixed",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                                   color : usernameLabelHover ? "#A5A5A5" : "#616161",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "0.569rem",
                                   zIndex : "6999",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setUsernameLabelHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setUsernameLabelHover(true);
                              }}
                         >          
                              <HUDTyper speed = {7} >
                                   {signedUser.username}
                              </HUDTyper>
                         </label>
                         
                         <div
                              style = {{
                                   position : "relative",
                                   height : "8.69rem",
                                   width : "calc(87%)",
                                   background : "transparent",
                                   marginTop : "1.469rem",
                              }}
                         >
                              
                         </div>
                         
                         {baseAccountCreationPanelVisible && usernameInputValue && passwordInputValue && usernameInputValue.trim() !== "" && passwordInputValue.trim() !== "" ? (
                         <div
                              style = {{
                                   position : "fixed",
                                   display : "flex",
                                   flexDirection : "column",
                                   alignItems : "center",
                                   height : "8.469rem",
                                   width : "calc(100%)",
                                   background : "rgba(0, 0, 0, 0.69)",
                                   visibility : processing ? "hidden" : "visible",
                                   borderRadius : "1rem 1rem 0rem 0rem",
                                   bottom : 0
                              }}
                         >
                              <PatrolBuddyGoActionButton
                                   setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                                   
                                   patrolBuddyGoActionButtonTitle = "Create Account"
                                   
                                   handlePatrolBuddyGoAction = {HandleBaseAccountCreation}
                              />
                         </div>
                         ) : pbgAccountCreationPanelVisible && firstNameInputValue && lastNameInputValue && usernameInputValue && emailInputValue && passwordInputValue && firstNameInputValue.trim() !== "" && lastNameInputValue.trim() !== "" && usernameInputValue.trim() !== "" && emailInputValue.trim() !== "" && passwordInputValue.trim() !== "" ?  (
                         <div
                              style = {{
                                   position : "fixed",
                                   display : "flex",
                                   flexDirection : "column",
                                   alignItems : "center",
                                   height : "8.469rem",
                                   width : "calc(100%)",
                                   background : "rgba(0, 0, 0, 0.69)",
                                   visibility : processing ? "hidden" : "visible",
                                   borderRadius : "1rem 1rem 0rem 0rem",
                                   bottom : 0
                              }}
                         >
                              <PatrolBuddyGoActionButton
                                   setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                                   
                                   patrolBuddyGoActionButtonTitle = "Create Account"
                                   
                                   handlePatrolBuddyGoAction = {HandleAccountCreation}
                              />
                         </div>
                         ) : (
                         <>
                         </>
                         )}
                         
                         {addressInputValue && (
                         <div
                              style = {{
                                   position : "fixed",
                                   display : "flex",
                                   flexDirection : "column",
                                   alignItems : "center",
                                   height : "8.469rem",
                                   width : "calc(100%)",
                                   background : "rgba(0, 0, 0, 0.69)",
                                   visibility : processing ? "hidden" : "visible",
                                   borderRadius : "1rem 1rem 0rem 0rem",
                                   bottom : 0
                              }}
                         >
                              <PatrolBuddyGoActionButton
                                   setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                                   
                                   patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                                   
                                   patrolBuddyGoActionButtonTitle = "Next"
                                   
                                   handlePatrolBuddyGoAction = {HandleLocality}
                              />
                         </div>
                         )}
                         
                         <ContactUsModal
                              contactUsModalVisibilityHandler = {setContactUsModalVisible}
                              
                              modalVisibility = {contactUsModalVisible}
                              
                              signedUser = {signedUser}
                              
                              signedIn = {signedIn}
                         >
                              
                         </ContactUsModal>
                    </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoAccountCreationPanel;