import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDNotification } from "./HUDNotificationProvider.js";

import PatrolCamViewer from "./PatrolCamViewer.js";

import HUDVideoPlayer from "./HUDVideoPlayer.js";











function PBGViewCell(pbgViewCellConfigurationSettings) 
{
    const {
         pbgViewCellContainerConfigurationSettings = {},
         pbgViewerConfigurationSettings = {},
         displayName = "",
         notificationViewHeaderTitle = "",
         notificationViewFooterTitle = "",
         pbgViewerHeight = "269px",
         pbgViewerWidth = "477px",
         pbgViewStatus = "Online",
         pbgViewSectionTitle = "",
         pbgViewActivity = [],
         pbgSignalSRC = "",
         pbgViewSRC = "rtsp://localhost:8554/leftcam",
         pbgViews = [],
         pbgViewData,
         signalMessage = "Offline",
         onVideoPauseAction,
         onVideoEndedAction,
         onVideoErrorAction,
         onVideoReadyAction,
         onVideoPlayAction,
         pbgViewControls = false,
         pbgViewPlaying = true,
         muted = false,
         volume,
         
         addToSessionCache,
         ...rest
    } = pbgViewCellConfigurationSettings;
    
    const { currentNotification, currentNotificationData, setCurrentNotificationData, displayNotification, notificationText, setNotificationText, notificationType, setNotificationType } = useHUDNotification();
    
    const [pbgViewVideoError, setPBGViewVideoError] = useState(null);
    
    const [pbgViewHovered, setPBGViewHovered] = useState(false);
    
    const [pbgViewVolume, setPBGViewVolume] = useState(volume);
    
    const [pbgViewMuted, setPBGViewMuted] = useState(muted);
    
    
    
    
    
    
    
    
    
    
    
    const pbgPanelNavigator = useNavigate();
    
    
    
    
    
    
    
    
    
    
    
    const handleSelectedPBGView = async (e) => {
         if (pbgViewData === undefined || HUDUniversalHUDUtilityManager.CheckEmptyData(pbgViewData)) { return; }
         
         addToSessionCache("CurrentPBGView", {
              ...pbgViewData,
              pbgViewControls : pbgViewControls,
              pbgViewPlaying : pbgViewPlaying,
              pbgViewMuted : pbgViewVolume,
              pbgViewVolume : pbgViewVolume
         });
         
         await Delay(269);
         
         pbgPanelNavigator(`/global-view/${pbgViewData.locality.county.replace(" ", "-").toLowerCase()}-${pbgViewData.locality.state.toLowerCase()}/${pbgViewData.serialK.toLowerCase()}`);
         
         await Delay(869);
         
         displayNotification(`Currently used by ${pbgViewData.user.displayName}`, "info", {
               notificationMessageLabelConfigurationSettings : {
                    color : "rgba(237, 237, 237, 1)",
               },
               notificationCloseButtonConfigurationSettings : {
                    color : "rgba(237, 237, 237, 1)",
               },
               iconColor : "rgba(237, 237, 237, 1)",
               notificationBaseConfigurationSettings : {
                    background : "rgba(21, 21, 21, 0.769)",
                    borderRadius : "0.69rem",
               },
               position : "bottom-right"
         });
         
         return;
    };
    
    
    
    
    
    
    
    
    
    
    
    return (
        <div
             style = {{
                  position : "relative",
                  display : "flex",
                  flexDirection : "column",
                  transition : "scale 0.369s ease",
                  borderRadius : "15px",
                  boxShadow : "3px 5px 21.69px -7.69px rgba(0, 0, 0, 1)",
                  background : "rgba(47, 47, 47, 1)",
                  height : "369.69px",
                  scale : pbgViewHovered ? "1" : "0.8969",
                  paddingBottom : "0.269rem",
                  paddingRight : "0.269rem",
                  paddingLeft : "0.269rem",
                  marginTop : "1.069rem",
                  ...pbgViewCellContainerConfigurationSettings
             }}
        >
             <div
                  style = {{
                       position : "relative",
                       display : "flex",
                       flexDirection : "row",
                       justifyContent : "flex-end",
                       marginBottom : "0.69rem",
                       marginRight : "0.269rem",
                       marginLeft : "0.269rem",
                       marginTop : "0.69rem",
                  }}
             >
                  <HUDIcon
                       iconConfigurationSettings = {{
                            cursor : "pointer",
                            marginLeft : "0.269rem",
                       }}
                       
                       color = {pbgViewMuted ? "rgba(69, 69, 69, 1)" : "rgba(87.69, 87.69, 115, 1)"}
                       
                       name = {pbgViewMuted ? "fa-volume-mute" : "fa-volume-up"}
                       
                       onClick = {() => {
                            const pbgVMuted = !pbgViewMuted;
                            
                            setPBGViewMuted(pbgVMuted);
                            
                            setPBGViewVolume(!pbgVMuted && volume !== 0 ? volume : volume === 0 && !pbgVMuted ? 0.469 : pbgVMuted ? 0 : 0);
                            
                            return;
                       }}
                  />
             </div>
             
             <label
                  style = {{
                       position : "relative",
                       display : "flex",
                       fontFamily : "ModeNine",
                       fontWeight : "bold",
                       fontSize : "1.269rem",
                       userSelect : "none",
                       color : "rgba(87.69, 87.69, 87.69, 0.869)",
                       marginBottom : "0.569rem",
                       marginLeft : "0.569rem",
                       marginTop : "0.569rem"
                  }}
             >
                  <HUDTyper speed = {3} >
                       {displayName}
                  </HUDTyper>
             </label>
             
             <label
                  style = {{
                       position : "relative",
                       display : "flex",
                       fontFamily : "ModeNine",
                       fontWeight : "bold",
                       fontSize : "1.0269rem",
                       userSelect : "none",
                       color : "rgba(87.69, 87.69, 87.69, 0.69)",
                       textIndent : "1rem",
                       marginLeft : "0.569rem",
                       marginTop : "0.569rem"
                  }}
             >
                  <HUDTyper speed = {5} >
                       {pbgViewSectionTitle}
                  </HUDTyper>
             </label>
             
             <div
                  style = {{
                       position : "relative",
                       display : "flex",
                       flexDirection : "column",
                       justifyContent : pbgViewSRC === "" ? "center" : null,
                       alignItems : pbgViewSRC === "" ? "center" : null,
                       userSelect : "none",
                       borderRadius : "15px",
                       boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                       background : pbgViewSRC === "" ? "rgba(5, 5, 5, 0.69)" : null,
                       height : pbgViewSRC !== "" ? "auto" : pbgViewerHeight,
                       width : pbgViewSRC !== "" ? "auto" : pbgViewerWidth,
                       paddingBottom : "0.69rem",
                       paddingRight : pbgViewSRC !== "" ? "0.269rem" : "0rem",
                       paddingLeft : pbgViewSRC !== "" ? "0.269rem" : "0rem",
                       marginTop : "1.269rem",
                  }}
             >
                  {pbgViewStatus.includes("Online") && pbgViewVideoError === null && pbgViewSRC !== "" ? (
                       <PatrolCamViewer
                            videoContainerConfigurationSettings = {{ 
                                 borderRadius : "1rem", 
                                 background : "black",
                                 marginTop : "0.469rem",
                                 ...pbgViewerConfigurationSettings
                            }}
                            videoPlaying = {pbgViewPlaying}
                            signalSRC = {pbgSignalSRC}
                            videoSRC = {pbgViewSRC}
                            controls = {pbgViewControls}
                            volume = {pbgViewVolume}
                            muted = {pbgViewMuted}
                            onVideoPlay = {(e) => {
                                 if (onVideoPlayAction)
                                 {
                                      onVideoPlayAction(e);
                                 }
                                 
                                 setPBGViewVideoError(null);
                            }}
                            onVideoPause = {onVideoPauseAction}
                            onVideoEnded = {onVideoEndedAction}
                            onVideoReady = {(e) => {
                                 if (onVideoReadyAction)
                                 {
                                      onVideoReadyAction(e, setPBGViewVideoError);
                                 }
                                 
                                 setPBGViewVideoError(null);
                            }}
                            onVideoError = {(e) => {
                                 if (onVideoErrorAction)
                                 {
                                      onVideoErrorAction(e, setPBGViewVideoError);
                                 }
                                 
                                 setPBGViewVideoError(e);
                            }}
                        >
                            <div
                                 style = {{
                                      position : "absolute",
                                      display : "flex",
                                      flexDirection : "column",
                                      justifyContent : "center",
                                      alignItems : "center",
                                      userSelect : "none",
                                      cursor : "pointer",
                                      transition : "background 0.69s ease",
                                      background : pbgViewHovered ? "rgba(0, 0, 0, 0.769)" : "transparent",
                                      height : "calc(100%)",
                                      width : "calc(100%)",
                                 }}
                                 
                                 onMouseLeave = {() => {
                                      setPBGViewHovered(false);
                                      
                                      return;
                                 }}
                                 
                                 onMouseEnter = {() => {
                                      setPBGViewHovered(true);
                                      
                                      return;
                                 }}
                                 
                                 onClick = {handleSelectedPBGView}
                            >
                                 {pbgViewHovered ? (
                                      <div
                                          style = {{
                                               position : "absolute",
                                               display : "flex",
                                               flexDirection : "column",
                                               width : "calc(100%)",
                                               top : 1
                                          }}
                                      >
                                           <label
                                                style = {{
                                                     position : "relative",
                                                     display : "flex",
                                                     fontFamily : "ModeNine",
                                                     fontWeight : "bold",
                                                     fontSize : "1.1269rem",
                                                     userSelect : "none",
                                                     cursor : "pointer",
                                                     color : "rgba(127.69, 127.69, 137.69, 1)",
                                                     marginLeft : "0.569rem",
                                                     marginTop : "0.569rem"
                                                }}
                                           >
                                                <HUDTyper speed = {1} delay = {269} >
                                                     {notificationViewHeaderTitle}
                                                </HUDTyper>
                                           </label>
                                      </div>
                                 ) : (
                                      <>
                                      </>
                                 )}
                                 
                                 {pbgViewHovered && pbgViewActivity.length > 0 ? (
                                      <label
                                           style = {{
                                                position : "relative",
                                                display : "flex",
                                                fontFamily : "ModeNine",
                                                fontWeight : "bold",
                                                fontSize : "1.1269rem",
                                                userSelect : "none",
                                                cursor : "pointer",
                                                color : "rgba(127.69, 127.69, 137.69, 1)",
                                                marginLeft : "0.569rem",
                                                marginTop : "0.569rem"
                                           }}
                                      >
                                           <HUDTyper speed = {3} delay = {369} >
                                                {pbgViewActivity[pbgViewActivity.length - 1]}
                                           </HUDTyper>
                                      </label>
                                 ) : pbgViewHovered ? (
                                      <label
                                           style = {{
                                                position : "relative",
                                                display : "flex",
                                                fontFamily : "ModeNine",
                                                fontWeight : "bold",
                                                fontSize : "1.1269rem",
                                                textAlign : "natural",
                                                userSelect : "none",
                                                cursor : "pointer",
                                                color : "rgba(127.69, 127.69, 137.69, 1)",
                                                paddingRight : "1rem",
                                                paddingLeft : "1rem",
                                                marginRight : "0.569rem",
                                                marginLeft : "0.569rem",
                                                marginTop : "0.569rem"
                                           }}
                                      >
                                           <HUDTyper speed = {3} delay = {369} >
                                                 No new notifications yet!
                                           </HUDTyper>
                                      </label>
                                 ) : (
                                      <>
                                      </>
                                 )}
                                 
                                 {pbgViewHovered ? (
                                      <div
                                          style = {{
                                               position : "absolute",
                                               display : "flex",
                                               flexDirection : "column",
                                               width : "calc(100%)",
                                               bottom : 5
                                          }}
                                      >
                                           <label
                                                style = {{
                                                     position : "relative",
                                                     display : "flex",
                                                     fontFamily : "ModeNine",
                                                     fontWeight : "bold",
                                                     fontSize : "1.1269rem",
                                                     userSelect : "none",
                                                     cursor : "pointer",
                                                     color : "rgba(127.69, 127.69, 137.69, 1)",
                                                     marginLeft : "0.569rem",
                                                     marginTop : "0.569rem"
                                                }}
                                           >
                                                <HUDTyper speed = {1} delay = {269} >
                                                     {notificationViewFooterTitle}
                                                </HUDTyper>
                                           </label>
                                      </div>
                                 ) : (
                                      <>
                                      </>
                                 )}
                            </div>
                        </PatrolCamViewer>
                   ) : (
                        <div
                             style = {{
                                 display : "flex",
                                 justifyContent : "center",
                                 alignItems : "center",
                                 borderRadius : "1rem", 
                                 background : "black",
                                 height : pbgViewerHeight, 
                                 width : pbgViewerWidth,
                                 marginTop : "0.469rem",
                             }}
                        >
                        <label
                             style = {{
                                  position : "relative",
                                  display : "flex",
                                  fontFamily : "ModeNine",
                                  fontWeight : "bold",
                                  fontSize : "2.169rem",
                                  color : "rgba(69.69, 69.69, 87.69, 0.769)",
                                  padding : "1rem"
                             }}
                        >
                             <HUDTyper speed = {7} >
                                  {signalMessage}
                             </HUDTyper>
                        </label>
                        </div>
                   )}
               </div>
         </div>
    )
}

export default PBGViewCell;