import { initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth';










const pbgDataCredentialConfiguration = {
     apiKey: "AIzaSyAwk8drlDAao1ymCnMAyjC6_3-i-H3fXmM",
     authDomain: "pbgcentral-82dac.firebaseapp.com",
     projectId: "pbgcentral-82dac",
     storageBucket: "pbgcentral-82dac.appspot.com",
     messagingSenderId: "998857615807",
     appId: "1:998857615807:web:6813972571fb06f673d90a",
     measurementId: "G-VY38YZRZHG"
};



const pbgCentral = initializeApp(pbgDataCredentialConfiguration);

const pbgAuth = getAuth(pbgCentral);

export {
     pbgAuth
}