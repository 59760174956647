import React, { useState, useRef, useEffect } from 'react';

import PatrolBuddyGoHUDUtilityManager from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import ContentModal from "./ContentModal.js";

import GRTyper from "./GRTyper.js";











const ContactUsModal = ({
     contactUsModalVisibilityHandler,
     modalVisibility,
     signedUser,
     signedIn,
}) => {
     const [modalCurrentlyAnimating, setModalCurrentlyAnimating] = useState(false);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <ContentModal
               modalVisibilityHandler = {contactUsModalVisibilityHandler}
               
               modalBackgroundConfiguration = {{
                            position : "absolute",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "center",
                            zIndex : "8699999",
                            bottom : 0,
                            right : 0,
                            left : 0,
                            top : 0,
               }}
               
               modalConfiguration = {{
                            position : "fixed",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "center",
                            background : "#e3e3e3",
                            width : "calc(57%)",
                            borderRadius : "2rem",
                            boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469), 0 5px 10px rgba(69, 69, 147, 0.69)",
                            transition : "opacity 0.569s ease-in-out",
                            opacity : modalCurrentlyAnimating === true ? 0 : 1,
                            paddingBottom : "3rem",
                            marginTop : "5%",
               }}
               
               modalBackgroundColor = "rgba(0, 0, 0, 0.69)"
               
               setModalAnimating = {setModalCurrentlyAnimating}
               
               modalAnimationInterval = {269}
               
               modalVisible = {modalVisibility}
          >
               <label
                     style = {{
                          position : "relative",
                          fontFamily : "ModeNine",
                          fontWeight : "bolder",
                          fontSize : "1.969rem",
                          transition : "font-size 0.3s ease-in-out",
                          wordWrap : "break-word",
                          width : "calc(91.69%)",
                          textAlign : "center",
                          color : "#292929",
                          marginTop : "1.469rem",
                          flexShrink : 1
                     }}
                >
                     <GRTyper speed = {3} >
                          Contact Us
                     </GRTyper>
                </label>
                
                <div
                     style = {{
                          position : "relative",
                          height : "0.269rem",
                          width : "calc(87%)",
                          background : "#c9c9c9",
                          marginTop : "1.469rem",
                     }}
                >
                     
                </div>
                
                <br></br>
                
                <label
                     style = {{
                          position : "relative",
                          fontFamily : "ModeNine",
                          fontWeight : "bold",
                          fontSize : "1.469rem",
                          transition : "font-size 0.3s ease-in-out",
                          wordWrap : "break-word",
                          width : "calc(91.69%)",
                          textAlign : "center",
                          color : "#4a4a4a",
                          marginTop : "1.469rem",
                          flexShrink : 1
                     }}
                >
                     <GRTyper speed = {7} >
                          Email: GeneralizedRobotics@generalizedrobotics.com
                     </GRTyper>
                </label>
                
                <br></br>
                
                {signedIn !== undefined && signedUser !== undefined && signedIn === true && PatrolBuddyGoHUDUtilityManager.CheckEmptyData(signedUser) === false ? (
                <label
                     style = {{
                          position : "relative",
                          fontFamily : "ModeNine",
                          fontWeight : "bold",
                          fontSize : "1.469rem",
                          transition : "font-size 0.3s ease-in-out",
                          wordWrap : "break-word",
                          width : "calc(91.69%)",
                          textAlign : "center",
                          color : "#4a4a4a",
                          marginTop : "1.469rem",
                          flexShrink : 1
                     }}
                >
                     <GRTyper speed = {7} >
                          Phone: 470-775-8231
                     </GRTyper>
                </label>
                ) : (
                <>
                </>
                )}
          </ContentModal>
     );
};

export default ContactUsModal;
