import React from "react";

import { useState, useEffect } from "react";

import { HUDRTSPViewer } from "@trap_stevo/legendarybuilderproreact-ui";











function PatrolCamViewer(hudVideoPlayerConfigurationSettings)
{
  const {
       viewerLoadingIndicatorConfigurationSettings = {},
       viewerContainerConfigurationSettings = {},
       videoContainerConfigurationSettings = {},
       videoControlsConfigurationSettings = {},
       videoFileConfigurationSettings = {},
       overlayConfigurationSettings = {},
       messageConfigurationSettings = {},
       videoConfigurationSettings = {},
       onVideoDuration,
       onVideoProgress,
       onVideoReady, 
       onVideoStart, 
       onVideoError,
       onVideoSeek,
       playsInline = true,
       controls = true,
       light = false,
       muted = false,
       volume = 0.8,
       videoPlaying = true,
       viewerResHeight = "1080px",
       viewerResWidth = "1920px",
       signalSRC,
       videoSRC,
       children,
       ...rest
  } = hudVideoPlayerConfigurationSettings;
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div 
         style = {{ 
              position : "relative",
              display : "flex",
              justifyContent : "center",
              alignItems : "center",
              clipPath : "inset(0% round 15px)",
              background : "rgba(3, 3, 3, 1)",
              height : "calc(100%)", 
              width : "calc(100%)",
              ...videoContainerConfigurationSettings
         }}
    >
         <HUDRTSPViewer
              videoContainerConfigurationSettings = {{
                   ...videoConfigurationSettings
              }}
              
              loadingSpinnerConfigurationSettings = {{
                   height : "47px",
                   width : "47px",
                   marginTop : "0rem",
                   ...viewerLoadingIndicatorConfigurationSettings
              }}
              
              containerConfigurationSettings = {{
                   height : "calc(100%)", 
                   width : "calc(100%)",
                   zIndex : 0,
                   ...viewerContainerConfigurationSettings
              }}
              
              overlayConfigurationSettings = {{
                   position : "absolute",
                   bottom : 0,
                   right : 0,
                   left : 0,
                   top : 0,
                   ...overlayConfigurationSettings
              }}
              
              messageConfigurationSettings = {{
                   fontSize : "1.269rem",
                   ...messageConfigurationSettings
              }}
              
              signalURL = {signalSRC}
              
              useGStreamer = {true}
              
              rtspURL = {videoSRC}
              
              height = {viewerResHeight}
              
              width = {viewerResWidth}
         />
         
         {children}
    </div>
  );
}

export default PatrolCamViewer;
