import React, { createContext, useContext, useState, useEffect } from 'react';

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDNotification } from "@trap_stevo/legendarybuilderproreact-ui";

const NotificationContext = createContext();











export const HUDNotificationProvider = ({ children, ...rest }) => {
  const [currentNotificationData, setCurrentNotificationData] = useState({});
  
  const [currentNotification, setCurrentNotification] = useState(<></>);
  
  const [activateNotification, setActivateNotification] = useState(false);
  
  const [notificationType, setNotificationType] = useState("info");
  
  const [notificationText, setNotificationText] = useState("");
  
  
  
  
  
  
  
  
  
  
  
  const displayNotification = async (text, type, notificationSettings) => {
       const displayedNotification = (
            <HUDNotification
                 message = {text}
                 type = {type}
                 motionDirection = "horizontal"
                 {...notificationSettings}
            />
       );
       
       setActivateNotification(true);
       
       setCurrentNotification(displayedNotification);
       
       await Delay(notificationSettings.duration ? notificationSettings.duration + 869 : 3869);
       
       setActivateNotification(false);
       
       return;
  };
  
  
  
  
  
  
  
  
  
  
  
  useEffect(() => {
       if (notificationText === "") { return () => {}; }
       
       displayNotification(notificationText, notificationType, currentNotificationData);
  }, [currentNotificationData, notificationText, notificationType]);
  
  
  
  
  
  
  
  
  
  
  
  
  return (
    <NotificationContext.Provider 
      value = {{ currentNotification, currentNotificationData, setCurrentNotificationData, notificationText, setNotificationText, notificationType, setNotificationType, displayNotification }}
    >
      {children}
      
      {activateNotification ? currentNotification : ( <></> )}
    </NotificationContext.Provider>
  );
};

export const useHUDNotification = () => {
  const context = useContext(NotificationContext);
  
  if (!context) 
  {
       throw new Error('Must use within an NotificationProvider');
  }
  
  return context;
};