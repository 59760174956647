import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyGoActionButton from "../HUDComponents/PatrolBuddyGoActionButton.js";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoProductPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("PatrolBuddyGo");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [downloading, setDownloading] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [currentProductShowcase, setCurrentProductShowcase] = useState(null);
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     
     
     
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     const productDetails = [
          {
               description : "Patrol Buddy Go is an advanced, on-car, camera system that utilizes artificial intelligence and state of the art components to ensure safety, reliability, and all surrounding visual awareness to the user and precinct.",
               productDetailOrientation : 0,
               showcaseMedia : require("../HUDContent/PatrolBuddyGoBodyShowcase.png"),
          },
          {
               description : "Officers can control this system via proprietary desktop application providing flexible, artificially intelligent camera footage, and more.",
               productDetailOrientation : 1,
               showcaseMedia : require("../HUDContent/PBGDashboardShowcase.png"),
          }
     ];
     
     const productShowcases = [
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbar.jpg"),
          },
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbarFrontView.jpg"),
          },
          {
               description : "Sits on top of a lightbar!",
               showcaseMedia : require("../HUDContent/PatrolBuddyGoOnLightbarSideView.jpg"),
          }
     ];
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, false, true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     async function HandlePBGDownload()
     {
          setDownloading(true);
          
          setProcessIndicatorTypeSpeed(21);
          
          setProcessIndicatorTitle("Downloading...");
          
          const serialKeyVerification = await PatrolBuddyGoHUDUtilityManager.VerifySerialKey(signedUser.serialKey);
          
          setTimeout(() => {
               if (serialKeyVerification === true)
               {
                    setProcessIndicatorTitle("Download Successful!");
               }
               else
               {
                    setProcessIndicatorTitle("Try Again...");
               }
               
               setProcessIndicatorColor("#616161");
               
               setDownloading(false);
           }, 3000);
           
           setTimeout(() => {
               setProcessIndicatorTitle("PatrolBuddyGo");
               
               setProcessIndicatorTypeSpeed(3);
           }, 5000);
           
           return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 269, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (downloading === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return;
     }, [downloading]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    background : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               <HUDSidePanel
                    sidePanelRef = {sidePanelRef}
                    
                    sidePanelMinimizeTabButtonConfigurationSettings = {{
                         background : "transparent",
                         color : "#A1A1A1"
                    }}
                    
                    sidePanelButtonUnderlineConfigurationSettings = {{
                         background : "linear-gradient(to right, #A1A1A1, white)"
                    }}
                    
                    sidePanelHeaderLogoConfigurationSettings = {{
                         transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                         scale : sidePanelLogoHover ? "1.069" : "1",
                         cursor : "pointer",
                         marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                    }}
                    
                    sidePanelContainerConfigurationSettings = {{
                         background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                    }}
                    
                    sidePanelTopHeaderConfigurationSettings = {{
                         marginTop : "0.69rem"
                    }}
                    
                    sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                    
                    sidePanelTopHeaderContents = {signedIn && signedUser.accessLevel && signedUser.accessLevel.accountAccessR > 0 ? [
                         <HUDIcon 
                              iconConfigurationSettings = {{
                                   transition : "scale 0.3s ease",
                                   scale : backButtonHover ? "1.069" : "1",
                                   cursor : "pointer"
                              }}
                              
                              name = "fa-chevron-left"
                              
                              color = "#A1A1A1"
                              
                              onMouseLeave = {() => {
                                   setBackButtonHover(false);
                                   
                                   return;
                              }}
                              
                              onMouseEnter = {() => {
                                   setBackButtonHover(true);
                                   
                                   return;
                              }}
                              
                              onClick = {() => {
                                   pbgPanelNavigator(-1);
                                   
                                   return;
                              }}
                         />
                    ] : []}
                    
                    sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverEnterAction = {() => {
                         setSidePanelLogoHover(true);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverExitAction = {() => {
                         setSidePanelLogoHover(false);
                         
                         return;
                    }}
                    
                    sidePanelButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoAction = {() => {
                         window.open("https://generalizedrobotics.com", "_blank")
                         
                         return;
                    }}
                    
                    logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                    
                    sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                    
                    minimizeTabPosition = "center-right"
                    
                    hideType = "Full"
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
               />
               
               <label
                    style = {{
                         position : "relative",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : patrolBuddyGoTitleHover ? "3.169rem" : "2.969rem",
                         color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "5.469rem",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setPatrolBuddyGoTitleHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setPatrolBuddyGoTitleHover(true);
                    }}
               >
                    <HUDTyper speed = {processIndicatorTypeSpeed} >
                         {processIndicatorTitle}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.469rem",
                         width : "calc(87%)",
                         background : "#333333",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               {productDetails.map((productDetailsData, index) => (
                    productDetailsData.productDetailOrientation === 1 ? (
                         <div
                              key = {index}
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   justifyContent : "center",
                                   height : "24rem",
                                   width : "calc(87%)",
                                   borderRadius : "1rem",
                                   background : "#333333",
                                   marginBottom : "2rem",
                                   marginTop : "2.469rem",
                                   overflow : "hidden",
                              }}
                         >
                              <img
                                   style = {{
                                        background : "transparent",
                                        height : "calc(90%)",
                                        width : "calc(50%)",
                                        borderRadius : "1rem",
                                        marginRight : "2.69rem",
                                        marginTop : "1.269rem",
                                   }}
                                   
                                   src = {productDetailsData.showcaseMedia}
                              />
                              
                              <label
                                   style = {{
                                        position : "relative",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bold",
                                        fontSize : "1.469rem",
                                        width : "calc(37%)",
                                        color : "#A5A5A5",
                                        marginTop : "3.469rem",
                                        flexShrink : 0
                                   }}
                              >
                                   <HUDTyper speed = {2} >
                                        {productDetailsData.description}
                                   </HUDTyper>
                              </label>
                         </div>
                    ) : (
                         <div
                              key = {index}
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   justifyContent : "center",
                                   height : "24rem",
                                   width : "calc(87%)",
                                   borderRadius : "1rem",
                                   background : "#333333",
                                   marginBottom : "2rem",
                                   marginTop : "2.469rem",
                                   overflow : "hidden"
                              }}
                         >
                              <label
                                   style = {{
                                        position : "relative",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bold",
                                        fontSize : "1.469rem",
                                        width : "calc(37%)",
                                        color : "#A5A5A5",
                                        marginRight : "2.69rem",
                                        marginTop : "3.469rem",
                                        flexShrink : 0
                                   }}
                              >
                                   <HUDTyper speed = {2} >
                                        {productDetailsData.description}
                                   </HUDTyper>
                              </label>
                              
                              <img
                                   style = {{
                                        background : "transparent",
                                        height : "calc(90%)",
                                        width : "calc(50%)",
                                        borderRadius : "1rem",
                                        marginTop : "1.269rem",
                                   }}
                                   
                                   src = {productDetailsData.showcaseMedia}
                              />
                         </div>
                    ))
               )}
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.469rem",
                         width : "calc(87%)",
                         background : "#333333",
                         marginBottom : "5rem",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               {productShowcases.map((productShowcaseData, index) => (
               <div
                    key = {index}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "center",
                         height : "24rem",
                         width : "calc(87%)",
                         borderRadius : "1rem",
                         background : "#333333",
                         marginBottom : "2rem",
                         marginTop : "2.469rem",
                    }}
               >
                    <img
                         style = {{
                              background : "transparent",
                              height : "calc(90%)",
                              width : "calc(30%)",
                              scale : currentProductShowcase === index ? "1.269" : "1",
                              transition : "scale 0.3s ease-in-out", 
                              boxShadow : "0 0 20px rgba(0, 0, 0, 0.69)",
                              borderRadius : "1rem",
                              marginLeft : "1rem",
                              marginTop : "1.269rem",
                         }}
                         
                         src = {productShowcaseData.showcaseMedia}
                         
                         onMouseLeave = {() => {
                              setCurrentProductShowcase(null);
                         }}
                         
                         onMouseEnter = {() => {
                              setCurrentProductShowcase(index);
                         }}
                    />
               </div>
               ))}
               
               {signedIn && (
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               )}
               
               <div
                    style = {{
                         position : "relative",
                         height : "15rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         height : "8.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.69)",
                         visibility : downloading ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         bottom : 0
                    }}
               >
                    <PatrolBuddyGoActionButton
                         setPatrolBuddyGoActionButtonHover = {setPatrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonHover = {patrolBuddyGoActionButtonHover}
                         
                         patrolBuddyGoActionButtonIcon = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                         
                         patrolBuddyGoActionButtonTitle = {signedIn ? "Download" : "Login"}
                         
                         HandlePatrolBuddyGoAction = {async (navigateTo) => {
                              if (signedIn === false)
                              {
                                   navigateTo("/authentication");
                                   
                                   return;
                              }
                              
                              HandlePBGDownload();
                              
                              return;
                         }}
                    />
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
          </div>
     );
}

export default PatrolBuddyGoProductPanel;