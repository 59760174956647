import React, { useState, useRef, useEffect } from 'react';











const ContentModal = ({ 
       modalBackgroundConfiguration, 
       modalBackgroundColor,
       modalConfiguration, 
       modalVisibilityHandler, 
       modalVisible,
       modalContentConfiguration,
       setModalAnimating,
       modalAnimationInterval,
       children
  }) => {
  const modalBackgroundRef = useRef(null);
  
  const modalForegroundRef = useRef(null);
  
  if (modalBackgroundColor === null || modalBackgroundColor === undefined)
  {
    modalBackgroundColor = "rgba(0, 0, 0, 0.7)";
  }
  
  useEffect(() => {
    const handleBackgroundClick = (event) => {
         if (modalBackgroundRef.current && modalBackgroundRef.current.contains(event.target) && !modalForegroundRef.current.contains(event.target)) 
         {
              setModalAnimating(true);
              
              setTimeout(() => {
                   modalVisibilityHandler("hidden");
              }, modalAnimationInterval !== undefined ? modalAnimationInterval : 1000);
              
              return;
         }
    };
    
    document.addEventListener('click', handleBackgroundClick);
    
    return () => {
         document.removeEventListener('click', handleBackgroundClick);
    };
  }, []);
  
  useEffect(() => {
    if (modalVisible === "hidden")
    {
         setModalAnimating(false);
         
         return;
    }
    
    return () => {};
  }, [modalVisible]);
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div 
      ref = {modalBackgroundRef}
      
      style = {{
           transition : "visibility 0.5s ease, background 1.5s ease",
           background : modalVisible ? modalBackgroundColor : "rgba(0, 0, 0, 0.69)",
           visibility : modalVisible,
           ...modalBackgroundConfiguration
      }}
    >
      <div 
        ref = {modalForegroundRef}
        
        style = {{
             visibility : modalVisible,
             ...modalConfiguration
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentModal;
