import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDSidePanel, HUDToolTip, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import PatrolCamViewer from "../HUDComponents/PatrolCamViewer.js";

import HUDVideoPlayer from "../HUDComponents/HUDVideoPlayer.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";

import { useHUDNotification } from "../HUDComponents/HUDNotificationProvider.js";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";











function PatrolBuddyGoPBGViewPanel(patrolBuddyGoPBGViewPanelConfigurationSettings) 
{
     const {
          pbgDisplayName = "",
          pbgViewTitle = "",
          pbgViewActivity = [],
          pbgSignalSRC = "",
          pbgViewSRC = "rtsp://localhost:8554/leftcam",
          pbgViewCurrentIndex,
          locality,
          serialK,
          user,
          currentPBGViews = [],
          onVideoPauseAction,
          onVideoEndedAction,
          onVideoErrorAction,
          onVideoPlayAction,
          pbgViewControls = false,
          pbgViewPlaying = true,
          pbgViewMuted = false,
          pbgViewVolume = 0
     } = patrolBuddyGoPBGViewPanelConfigurationSettings;
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState(pbgViewTitle);
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.169rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("rgba(147.69, 147.69, 147.69, 1)");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const { currentNotification, currentNotificationData, setCurrentNotificationData, displayNotification, notificationText, setNotificationText, notificationType, setNotificationType } = useHUDNotification();
     
     const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     
     
     
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("Global View");
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.169rem");
               
               setProcessIndicatorColor("rgba(147.69, 147.69, 147.69, 1)");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "rgba(147.69, 147.69, 147.69, 1)" ? "#fa0707" : "rgba(147.69, 147.69, 147.69, 1)"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "hud-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    borderRadius : "0rem",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "hidden"
               }}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <HUDSidePanel
                    sidePanelRef = {sidePanelRef}
                    
                    sidePanelMinimizeTabButtonConfigurationSettings = {{
                         background : "transparent",
                         color : "#A1A1A1"
                    }}
                    
                    sidePanelButtonUnderlineConfigurationSettings = {{
                         background : "linear-gradient(to right, #A1A1A1, white)"
                    }}
                    
                    sidePanelHeaderLogoConfigurationSettings = {{
                         transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                         scale : sidePanelLogoHover ? "1.069" : "1",
                         cursor : "pointer",
                         marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                    }}
                    
                    sidePanelContainerConfigurationSettings = {{
                         background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                    }}
                    
                    sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                    
                    sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
                    
                    sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverEnterAction = {() => {
                         setSidePanelLogoHover(true);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverExitAction = {() => {
                         setSidePanelLogoHover(false);
                         
                         return;
                    }}
                    
                    sidePanelButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoAction = {() => {
                         window.open("https://generalizedrobotics.com", "_blank")
                         
                         return;
                    }}
                    
                    logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                    
                    sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                    
                    minimizeTabPosition = "center-right"
                    
                    hideType = "Full"
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
               />
               
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "flex-start",
                         alignItems : "center",
                         background : "linear-gradient(to bottom, rgba(0, 0, 0, 0.69), transparent)",
                         width : "calc(100%)",
                         flexShrink : 0,
                         zIndex : 69,
                         paddingBottom : "1.69rem",
                         paddingTop : "1rem",
                         top : 0,
                         right : 15,
                         left : 0,
                    }}
               >
                    <HUDToolTip
                         toolTipConfigurationStyle = {{
                              background : "rgba(0, 0, 0, 0.369)",
                              borderRadius : "0.69rem",
                              boxShadow : "none",
                              color : "rgba(227, 227, 227, 1)",
                              paddingBottom : "0.369rem",
                              marginLeft : "0.269rem",
                         }}
                         position = "right"
                         text = "Back"
                    >
                    <HUDIcon
                         iconConfigurationSettings = {{
                              display : "flex",
                              cursor : "pointer",
                              transition : "background 0.69s ease-in-out",
                              borderRadius : "1rem",
                              background : backButtonHover ? "rgba(15, 15, 15, 1)" : "rgba(0, 0, 0, 0.369)",
                              height : "1.269rem",
                              width : "1.369rem",
                              padding : "0.469rem",
                              marginLeft : "0.569rem",
                         }}
                         
                         color = "rgba(147.69, 147.69, 147.69, 1)"
                         
                         name = "fa-chevron-left"
                         
                         onMouseLeave = {() => {
                              setBackButtonHover(false);
                              
                              return;
                         }}
                         
                         onMouseEnter = {() => {
                              setBackButtonHover(true);
                              
                              return;
                         }}
                         
                         onClick = {() => {
                              addToSessionCache("CurrentPBGView", {});
                              
                              pbgPanelNavigator("/global-view");
                              
                              return;
                         }}
                    />
                    </HUDToolTip>
                    
                    <Fade direction = "left" duration = {469} >
                    <label
                         style = {{
                              position : "relative",
                              display : "flex",
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out, margin-left 0.269s ease-in-out",
                              background : "rgba(0, 0, 0, 0.369)",
                              borderRadius : "0.69rem",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : processIndicatorFontSize,
                              color : patrolBuddyGoTitleHover ? "rgba(187.69, 187.69, 187.69, 1)" : processIndicatorColor,
                              flexShrink : 0,
                              paddingBottom : "0.469rem",
                              paddingRight : "0.569rem",
                              paddingLeft : "0.569rem",
                              paddingTop : "0.469rem",
                              marginLeft : backButtonHover ? "3.69rem" : "0.69rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {`${pbgDisplayName} (${serialK}) : ${locality.county}, ${locality.state}`}
                         </HUDTyper>
                    </label>
                    </Fade>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "rgba(147.69, 147.69, 147.69, 1)",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <PatrolCamViewer
                    videoContainerConfigurationSettings = {{
                         position : "relative",
                         borderRadius : "0rem",
                    }}
                    
                    viewerLoadingIndicatorConfigurationSettings = {{
                         height : "69px",
                         width : "69px"
                    }}
                    
                    viewerContainerConfigurationSettings = {{
                         position : "relative",
                         height : "100vh",
                         width : "100vw",
                    }}
                    
                    messageConfigurationSettings = {{
                         fontSize : "1.69rem"
                    }}
                    
                    videoConfigurationSettings = {{ 
                         borderRadius : "0rem",
                         background : "black",
                         height : "calc(100%)", 
                         width : "100vw",
                         right : 0,
                    }}
                    
                    signalSRC = {pbgSignalSRC}
                    
                    videoSRC = {pbgViewSRC}
               >
               </PatrolCamViewer>
               {/*<HUDVideoPlayer
                    videoContainerConfigurationSettings = {{ 
                         position : "absolute",
                         background : "black",
                         height : "100vh", 
                         width : "100vw",
                    }}
                    videoConfigurationSettings = {{ 
                         position : "relative",
                         background : "#1a1010", 
                         height : "100%", 
                         width : "100%",
                    }}
                    videoPlaying = {pbgViewPlaying}
                    videoSRC = {pbgViewSRC}
                    controls = {pbgViewControls}
                    volume = {pbgViewVolume}
                    muted = {pbgViewMuted}
                    onVideoPlay = {onVideoPlayAction}
                    onVideoPause = {onVideoPauseAction}
                    onVideoEnded = {onVideoEndedAction}
                    onVideoError = {onVideoErrorAction}
               >
               </HUDVideoPlayer>*/}
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoPBGViewPanel;