import { useNavigate } from "react-router-dom";

import { signInWithEmailAndPassword } from "firebase/auth";

import { pbgAuth } from "../HUDComponents/PBGCentral.js";

import axios from "axios";











const pbgNetworkURL = "wss://utilities.patrolbuddygo.com";

const pbgAPIURL = "https://utilities.patrolbuddygo.com";

const ProcessIndicatorUpdater = (indicatorHandler, systemMessage) => {
     if (indicatorHandler)
     {
          indicatorHandler(systemMessage);
          
          return;
     }
};

export function PatrolBuddyGoAccountCreationPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/account-creation');
     
     return;
};

export function PatrolBuddyGoAuthenticationPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/authentication');
     
     return;
};

export function PatrolBuddyGoSupportAddChatPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/support/new-chat');
     
     return;
};

export function PatrolBuddyGoSupportChatPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/support/chat');
     
     return;
};

export function PatrolBuddyGoControlPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/control-panel');
     
     return;
};

export function PatrolBuddyGoProductPanelNavigationActivation(PBGNavigation) 
{
     PBGNavigation('/');
     
     return;
};

export function PatrolBuddyGoAccessNavigationActivation(PBGNavigation, signedUser)
{
     if (signedUser.accessLevel !== undefined && signedUser.accessLevel.accountAccessR > 0)
     {
          PatrolBuddyGoControlPanelNavigationActivation(PBGNavigation);
          
          return;
     }
     
     PatrolBuddyGoProductPanelNavigationActivation(PBGNavigation);
     
     return;
};

export function PatrolBuddyGoHUDDataReloader(setSignedUser, setSessionCache, signIn)
{
     try
     {
          const storedPBGUserCredentialData = localStorage.getItem("SignedUserCredential");
          
          const storedHUDSessionCache = localStorage.getItem("HUDSessionCache");
          
          if (storedPBGUserCredentialData) 
          {
               const pbgUserCredentialData = JSON.parse(storedPBGUserCredentialData);
               
               if (Object.keys(pbgUserCredentialData).length > 0)
               {
                    setSignedUser(pbgUserCredentialData);
                    
                    signIn();
               }
          }
          
          if (storedHUDSessionCache) 
          {
               const hudSessionCacheData = JSON.parse(storedHUDSessionCache);
               
               if (Object.keys(hudSessionCacheData).length > 0)
               {
                    setSessionCache(hudSessionCacheData);
               }
          }
          
          return;
     }
     catch (error)
     {
          return;
     }
}



const PatrolBuddyGoHUDUtilityManager = {
  ScrollToElement : function(document, elementId) {
    const element = document.getElementById(elementId);
    
    if (element) 
    {
         element.scrollIntoView({ behavior: 'smooth' });
         
         return;
    }
    
    return;
  },
  
  CheckEmptyData : function(data)
  {
    return Object.keys(data).length === 0;
  },
  
  CheckPatrolBuddyGoUserAccessLevel : async function(username)
  {
    const desiredAccessProtocolData = await axios({
         url: pbgAPIURL + "/pbg-users/getPatrolBuddyGoUserAccountAccessLevel",
         method: "GET",
         params: {
                   "inputUsername": username
         },
         responseType: "json",
         timeout: 5000,
    });
    
    return desiredAccessProtocolData;
  },
  
  CheckPatrolBuddyGoUserLocality : async function(username)
  {
    const desiredLocalityData = await axios({
         url: pbgAPIURL + "/pbg-users/getPatrolBuddyGoUserLocality",
         method: "GET",
         params: {
                   "inputUsername": username
         },
         responseType: "json",
         timeout: 5000,
    });
    
    return desiredLocalityData;
  },
  
  CheckPatrolBuddyGoUserDisplayName : async function(username)
  {
    const desiredDisplayNameData = await axios({
         url: pbgAPIURL + "/pbg-users/getPatrolBuddyGoUserDisplayName",
         method: "GET",
         params: {
                   "inputUsername": username
         },
         responseType: "json",
         timeout: 5000,
    });
    
    return desiredDisplayNameData;
  },
  
  CheckPatrolBuddyGoUserID : async function(username)
  {
    const desiredUserIDData = await axios({
         url: pbgAPIURL + "/pbg-users/getPatrolBuddyGoUserID",
         method: "GET",
         params: {
                   "inputUsername": username
         },
         responseType: "json",
         timeout: 5000,
    });
    
    return desiredUserIDData;
  },
  
  CheckLocalStorageTemporanceInterval : function(hours = 24) 
  {
    const now = new Date();
    
    const dataInitInterval = localStorage.getItem('dataTemporance');
    
    if (!dataInitInterval) 
    {
        localStorage.setItem('dataTemporance', now.getTime().toString());
        
        return false;
    }
    
    if (now.getTime() - parseInt(dataInitInterval) > hours * 60 * 60 * 1000) 
    {
        localStorage.clear();
        
        localStorage.setItem('dataTemporance', now.getTime().toString());
        
        return true;
    }
    
    return false;
  },
  
  ConfirmPatrolBuddyGoControlPanelAccess : function(userAccessLevelData)
  {
    if (userAccessLevelData === undefined) { return false; }
    
    const userAccessR = userAccessLevelData.accountAccessR;
    
    const userAccessP = userAccessLevelData.accountAccessLevel;
    
    if (userAccessR === undefined || userAccessP === undefined || userAccessR === null || userAccessP === null) { return false; }
    
    if (userAccessR > 0 && userAccessP !== "user") { return true; }
    
    if (userAccessR > 0) { return true; }
    
    return false;
  },
  
  SetTemporaryData : function(id, inputData, hours = 24) 
  {
    const now = new Date();
   
    const data = {
        currentData : inputData,
        expiration : now.getTime() + (hours * 60 * 60 * 1000),
    };

    localStorage.setItem(id, JSON.stringify(data));
    
    return;
  },
  
  SendDataThroughPatrolBuddyGoSupportNetwork : function(patrolBuddyGoSupportNetworkAccess, data)
  {
    if (patrolBuddyGoSupportNetworkAccess.readyState === WebSocket.OPEN) 
    {
         patrolBuddyGoSupportNetworkAccess.send(JSON.stringify(data));
         
         return;
    }
    
    console.log("PatrolBuddyGo Support Network Access closed.");
    
    return;
  },
  
  AddPBGLocality : async function(address)
  {
    try
    {
         const desiredLocality = await axios({
              url: pbgAPIURL + "/localities/addPatrolBuddyGoLocality",
              method: "POST",
              params: {
                   "inputAddress": address
              },
              responseType: "json",
              timeout: 5000,
         });
         
         console.log("Added PatrolBuddyGo locality....");
         
         return desiredLocality.data;
    }
    catch (error)
    {
         console.log("Did not add PatrolBuddyGo locality....");
         
         return null;
    }
  },
  
  AddPBGUserBaseAccount : async function(username, password, county)
  {
    try
    {
         const desiredPBGBaseAccount = await axios({
              url: pbgAPIURL + "/pbg-users/addPatrolBuddyGoUserBaseAccount",
              method: "POST",
              params: {
                   "inputUsername" : username,
                   "inputPassword" : password,
                   "county" : county
              },
              responseType: "json",
              timeout: 5000,
         });
         
         console.log("Added base account: " + desiredPBGBaseAccount.data.username + "!");
         
         return true;
    }
    catch (error)
    {
         console.log("Did not add base account....", error);
         
         return false;
    }
  },
  
  AddPBGUserAccount : async function(firstName, middleName, lastName, username, email, password, rank, county)
  {
    try
    {
         if (county === null) { return false; }
         
         const desiredPBGUserAccount = await axios({
              url: pbgAPIURL + "/pbg-users/addPatrolBuddyGoUserAccount",
              method: "POST",
              params: {
                   "inputFirstName" : firstName,
                   "inputMiddleName" : middleName,
                   "inputLastName" : lastName,
                   "inputUsername" : username,
                   "inputEmail" : email,
                   "inputPassword" : password,
                   "inputRank" : rank,
                   "county" : county
              },
              responseType: "json",
              timeout: 5000,
         });
         
         console.log("Added PBG account: " + desiredPBGUserAccount.data.username + "!");
         
         return true;
    }
    catch (error)
    {
         console.log("Did not add PBG account....", error);
         
         return false;
    }
  },
  
  GetAddresses : async function(address)
  {
    try
    {
         const desiredAddresses = await axios({
              url: pbgAPIURL + "/localities/getAddresses",
              method: "GET",
              params: {
                   "inputAddress": address
              },
              responseType: "json",
              timeout: 5000,
         });
         
         console.log("Got " + desiredAddresses.data.length + " address(es).");
         
         return desiredAddresses.data;
    }
    catch (error)
    {
         console.log("Did not get addresses.");
         
         return null;
    }
  },
  
  GetPatrolBuddyGoSupportNetworkAccess : function(p, gateway = "supportP", onOpenAction)
  {
       const patrolBuddyGoSupportNetwork = new WebSocket(`${pbgNetworkURL}/${gateway}`);
       
       patrolBuddyGoSupportNetwork.onopen = () => {
            console.log(`Entered the ${gateway} network.`);
            
            if (typeof onOpenAction === "function") 
            {
                 onOpenAction();
            }
       };
       
       return patrolBuddyGoSupportNetwork;
  },
  
  GetPatrolBuddyGoUserActionTraces : async function(userID)
  {
    try
    {
         const desiredUserActionTraces = await axios({
              url: pbgAPIURL + "/pbg-traces/getPatrolBuddyGoUserActionTraces",
              method: "GET",
              params: {
                   "inputUserID": userID
              },
              responseType: "json",
              timeout: 5000,
         });
         
         return desiredUserActionTraces.data;
    }
    catch (error)
    {
         return [];
    }
  },
  
  GetTemporaryData : function(id) 
  {
    const data = localStorage.getItem(id);
    
    if (!data) { return null; }

    const item = JSON.parse(data);
    
    const now = new Date();
    
    if (now.getTime() >= item.expiration) 
    {
        localStorage.removeItem(id);
        
        return null;
    }

    return item.currentData;
  },
  
  AuthenticateUserWithEmailAndPassword : async function(serialKey, username, password, signedUser, setSignedUser, signedIn, signIn, signOut)
  {
    try
    {
         if (PatrolBuddyGoHUDUtilityManager.CheckEmptyData(signedUser) === false && signedIn == true) { return false; }
         
         const desiredEmailData = await axios({
              url: pbgAPIURL + "/pbg-users/getPatrolBuddyGoUserEmail",
              method: "GET",
              params: {
                   "inputUsername": username
              },
              responseType: "json",
              timeout: 5000,
         });
         
         const desiredEmail = desiredEmailData.data.email;
         
         const userAccessProtocolData = await PatrolBuddyGoHUDUtilityManager.CheckPatrolBuddyGoUserAccessLevel(username);
         
         const displayNameData = await PatrolBuddyGoHUDUtilityManager.CheckPatrolBuddyGoUserDisplayName(username);
         
         const localityData = await PatrolBuddyGoHUDUtilityManager.CheckPatrolBuddyGoUserLocality(username);
         
         const userIDData = await PatrolBuddyGoHUDUtilityManager.CheckPatrolBuddyGoUserID(username);
         
         const userCredential = await signInWithEmailAndPassword(pbgAuth, desiredEmail, password);
         
         const pbgUserCredentialData = {
              accessLevel: userAccessProtocolData.data,
              locality : localityData.data ? localityData.data.locality : null,
              email: desiredEmail,
              displayName: displayNameData.data.displayName,
              userID: userIDData.data.userID,
              username: username,
              serialKey: serialKey,
         };
         
         setSignedUser(pbgUserCredentialData);
         
         signIn();
         
         localStorage.setItem("SignedUserCredential", JSON.stringify(pbgUserCredentialData));
         
         localStorage.setItem("UserSignedIn", signedIn);
         
         console.log("PBG User authenticated using email and password.");
         
         return true;
    }
    catch (error)
    {
         console.log("Did not authenticate user with email and password.");
         
         return false;
    }
  },
  
  AuthenticateUser : async function(serialKey, username, password, signedUser, setSignedUser, signedIn, signIn, signOut)
  {
    try
    {
         const pbgUserCredential = localStorage.getItem("SignedUserCredential");
         
         const pbgUserSignedIn = localStorage.getItem("UserSignedIn");
         
         if (PatrolBuddyGoHUDUtilityManager.CheckEmptyData(signedUser) === false && signedIn == true) { return false; }
         
         var authenticated = await PatrolBuddyGoHUDUtilityManager.AuthenticateUserWithEmailAndPassword(serialKey, username, password, signedUser, setSignedUser, signedIn, signIn, signOut);
         
         if (authenticated === false)
         {
              const response = await axios({
                   url: pbgAPIURL + "/pbg-auth/user/patrolBuddyGoUserAuthentication",
                   method: "GET",
                   params: {
                        "inputSerialKey": serialKey,
                        "inputUsername": username,
                        "inputPassword": password
                   },
                   responseType: "json",
                   timeout: 5000,
              });
              
              if (response.status >= 200 && response.status < 300) 
              {
                   setSignedUser(response.data);
                   
                   console.log("Logged in with base account");
                   
                   signIn();
                   
                   localStorage.setItem("SignedUserCredential", JSON.stringify(response.data));
                   
                   localStorage.setItem("UserSignedIn", signedIn);
                   
                   return true;
              }
              
              return false;
         }
         
         return authenticated;
    }
    catch (error)
    {
         return false;
    }
  },
  
  DownloadPatrolBuddyGo : async function(serialKey) 
  {
    try 
    {
         const response = await axios({
              url: pbgAPIURL + "/pbg-downloads/download-patrolbuddygo-dispatcher",
              method: "GET",
              params: {
                   "inputSerialKey": serialKey
              },
              responseType: "json",
              timeout: 5000,
         });
         
         /*const blob = new Blob([response.data], { type: response.headers['content-type'] });
         
         const url = window.URL.createObjectURL(blob);
         
         const link = document.createElement('a');
         
         link.href = url;
         
         link.setAttribute('download', `PatrolBuddyGoDispatcher.exe`);
            
         document.body.appendChild(link);
         
         link.hidden = true;
         
         link.click();
         
         document.body.removeChild(link);*/
         
         return response.data.downloaded;
    } 
    catch (error) 
    {
         return false;
    }
  },
  
  VerifySerialKey : async function(serialKey) 
  {
    try 
    {
         const response = await axios({
              url: pbgAPIURL + "/pbg-keys/verify-pbg-serial",
              method: "GET",
              params: {
                   "inputSerialKey": serialKey
              },
              responseType: "json",
              timeout: 5000,
         });
         
         return response.data.verified;
    } 
    catch (error) 
    {
         return false;
    }
  }
};

export default PatrolBuddyGoHUDUtilityManager;
