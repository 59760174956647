import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoSupportChatPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











function PatrolBuddyGoSupportPanel() 
{
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Support");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [downloading, setDownloading] = useState(false);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [currentOnGoingChatHover, setCurrentOnGoingChatHover] = useState(false);
     
     const [loadingCurrentChats, setLoadingCurrentChats] = useState(false);
     
     const [currentOnGoingChat, setCurrentOnGoingChat] = useState(null);
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, true);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("", true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     const [currentOnGoingChatsData, setCurrentOnGoingChatsData] = useState([]);
     
     
     
     
     
     
     
     
     
     
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (sessionCache["CurrentChats"])
          {
               setCurrentOnGoingChatsData([...currentOnGoingChatsData, ...sessionCache["CurrentChats"]]);
          }
          
          if (signedUser === undefined || Object.keys(signedUser).length <= 0) { return () => {}; }
          
          const patrolBuddyGoSupportNetworkAccess = PatrolBuddyGoHUDUtilityManager.GetPatrolBuddyGoSupportNetworkAccess(8699, "supportP", () => {
          const supportNetworkMostRecentChatUpdate = setTimeout(() => {
               var patrolBuddyGoSupportNetworkAction = {
                    userID : signedUser.userID,
                    username : signedUser.username,
                    access : signedUser.accessLevel,
                    action : "getChatsOnStatus",
               };
               
               setLoadingCurrentChats(true);
               
               PatrolBuddyGoHUDUtilityManager.SendDataThroughPatrolBuddyGoSupportNetwork(patrolBuddyGoSupportNetworkAccess, patrolBuddyGoSupportNetworkAction);
          }, 100);
          
          return () => {clearInterval(supportNetworkMostRecentChatUpdate);};
          });
          
          
          
          patrolBuddyGoSupportNetworkAccess.onmessage = (event) => {
               const chatEventData = JSON.parse(event.data);
               
               var eventReceivers = {
                   "mostRecentChat" : function()
                   {
                        const containsChat = currentOnGoingChatsData.some(chat => chat.ChatID === chatEventData.data.ChatID);
                        
                        if (!containsChat)
                        {
                             setCurrentOnGoingChatsData([...currentOnGoingChatsData, chatEventData.data]);
                        }
                   },
                   
                   "chatsOnStatus" : function()
                   {
                        const currentChats = chatEventData.data.filter((chat) => chat !== undefined && chat !== null);
                        
                        setCurrentOnGoingChatsData([...currentChats]);
                   }
               };
               
               if (eventReceivers[chatEventData.type])
               {
                    eventReceivers[chatEventData.type]();
               }
               
               
               
               addToSessionCache("CurrentChats", currentOnGoingChatsData);
               
               setLoadingCurrentChats(false);
          };
          
          return () => {
               patrolBuddyGoSupportNetworkAccess.close();
          };
     }, [signedUser]);
     
     useEffect(() => {
          if (downloading === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return () => {};
     }, [downloading]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : "#201C1D",
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <HUDSidePanel
                    sidePanelRef = {sidePanelRef}
                    
                    sidePanelMinimizeTabButtonConfigurationSettings = {{
                         background : "transparent",
                         color : "#A1A1A1"
                    }}
                    
                    sidePanelButtonUnderlineConfigurationSettings = {{
                         background : "linear-gradient(to right, #A1A1A1, white)"
                    }}
                    
                    sidePanelHeaderLogoConfigurationSettings = {{
                         transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                         scale : sidePanelLogoHover ? "1.069" : "1",
                         cursor : "pointer",
                         marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
                    }}
                    
                    sidePanelContainerConfigurationSettings = {{
                         background : !sidePanelVisible ? "rgba(69, 69, 69, 1)" : "linear-gradient(to bottom, #1e1e1f, #1a1313)",
                    }}
                    
                    sidePanelTopHeaderConfigurationSettings = {{
                         marginTop : "0.69rem"
                    }}
                    
                    sidePanelTopHeaderContents = {signedIn && signedUser.accessLevel && signedUser.accessLevel.accountAccessR > 0 ? [
                         <HUDIcon 
                              iconConfigurationSettings = {{
                                   transition : "scale 0.3s ease",
                                   scale : backButtonHover ? "1.069" : "1",
                                   cursor : "pointer"
                              }}
                              
                              name = "fa-chevron-left"
                              
                              color = "#A1A1A1"
                              
                              onMouseLeave = {() => {
                                   setBackButtonHover(false);
                                   
                                   return;
                              }}
                              
                              onMouseEnter = {() => {
                                   setBackButtonHover(true);
                                   
                                   return;
                              }}
                              
                              onClick = {() => {
                                   pbgPanelNavigator(-1);
                                   
                                   return;
                              }}
                         />
                    ] : []}
                    
                    sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
                    
                    sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverEnterAction = {() => {
                         setSidePanelLogoHover(true);
                         
                         return;
                    }}
                    
                    sidePanelLogoHoverExitAction = {() => {
                         setSidePanelLogoHover(false);
                         
                         return;
                    }}
                    
                    sidePanelButtonAction = {(sidePanelButton, index) => {
                         if (sidePanelButton.hudAction)
                         {
                              sidePanelButton.hudAction();
                              
                              return;
                         }
                         
                         pbgPanelNavigator(sidePanelButton.navigationID);
                         
                         return;
                    }}
                    
                    sidePanelLogoAction = {() => {
                         window.open("https://generalizedrobotics.com", "_blank")
                         
                         return;
                    }}
                    
                    logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
                    
                    sidePanelCurrentButtonIndicatorHoverColor = "#A1A1A1"
                    
                    sidePanelButtonBackgroundHoverColor = "#A1A1A1"
                    
                    minimizeTabPosition = "center-right"
                    
                    hideType = "Full"
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
               />
               
               <label
                    style = {{
                         position : "relative",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                         color : patrolBuddyGoTitleHover ? "#A1A1A1" : processIndicatorColor,
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "3.69rem",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setPatrolBuddyGoTitleHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setPatrolBuddyGoTitleHover(true);
                    }}
               >
                    <HUDTyper speed = {processIndicatorTypeSpeed} >
                         {processIndicatorTitle}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0.269rem",
                         width : "calc(87%)",
                         background : "#333333",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         transition : "height 0.269s ease-in",
                         width : "calc(94.69%)",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : "rgba(12.69, 12.69, 12.69, 0.569)",
                         marginBottom : "1rem",
                         marginTop : "2.69rem",
                    }}
               >
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.269s ease-in",
                         height : "43.69rem",
                         width : "calc(41.69%)",
                         overflowY : "scroll",
                         overflowX : "hidden",
                         borderRadius : "1rem",
                         boxShadow : "0 0 10px rgba(0, 0, 0, 0.469)",
                         background : "rgba(31, 31, 31, 0.169)",
                         marginBottom : "1rem",
                         marginLeft : "1rem",
                         marginTop : "0.869rem",
                    }}
               >
               {currentOnGoingChatsData.length > 0 ? currentOnGoingChatsData.map((currentOnGoingChatData, index) => (
               <div
                    key = {index}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         height : "12.169rem",
                         transition : "height 0.269s ease-in, background 0.69s ease-in-out, scale 0.369s ease-in, box-shadow 0.569s ease-in",
                         boxShadow : currentOnGoingChat === index ? "inset 0 0 10px rgba(0, 0, 0, 0.69)" : "3px 3px 9.69px -2.69px rgba(0, 0, 0, 0.469)",
                         scale : currentOnGoingChat === index ? "1.10069" : "1",
                         width : "calc(95.69%)",
                         borderRadius : "1rem",
                         background : currentOnGoingChat === index ? "rgba(69, 69, 69, 0.569)" : "rgba(31, 31, 31, 0.469)",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    
                    onMouseLeave = {() => {
                         setCurrentOnGoingChatHover(false);
                         
                         setCurrentOnGoingChat(null);
                    }}
                    
                    onMouseEnter = {() => {
                         setCurrentOnGoingChatHover(true);
                         
                         setCurrentOnGoingChat(index);
                    }}
                    
                    onClick = {() => {
                         localStorage.setItem("CurrentSupportChat", JSON.stringify(currentOnGoingChatData));
                         
                         PatrolBuddyGoSupportChatPanelNavigationActivation(pbgPanelNavigator);
                         
                         return;
                    }}
               >
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              transition : "height 0.269s ease-in",
                              width : "calc(87%)",
                         }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : "#A5A5A5",
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.ChatTitle}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "right",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : "#A5A5A5",
                              width : "calc(48.069%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginTop : "0.969rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.MostRecentMessageDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.969rem",
                              color : "#A5A5A5",
                              width : "calc(87.69%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "1.269rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.MostRecentMessageSender}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bold",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.9169rem",
                              color : "transparent",
                              height : "4.1069rem",
                              width : "calc(85.69%)",
                              background : "linear-gradient(to bottom, #A5A5A5 0%, transparent 79.69%)",
                              WebkitBackgroundClip : "text",
                              WebkitTextFillColor : "transparent",
                              overflowY : "hidden",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "2.269rem",
                              marginTop : "1.069rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.MostRecentMessage}
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              width : "calc(91.69%)",
                              marginTop : "0.069rem",
                         }}
                    >
                    <div
                         style = {{
                              position : "relative",
                              background : "#787878",
                              borderRadius : "3rem",
                              height : "1.269rem",
                              width : "1.269rem",
                              marginLeft : "0.869rem",
                              marginBottom : "0.69rem",
                         }}
                    >
                         
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.9269rem",
                              color : "#7d7d7d",
                              width : "calc(77.69%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.469rem",
                              marginBottom : "0.369rem",
                              marginTop : "0.169rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.ChatStatus}
                         </HUDTyper>
                    </label>
                    </div>
               </div>
               )) : loadingCurrentChats === true ? (
                    <>
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : "2.269rem",
                                   color : "#A5A5A5",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "1.69rem",
                                   flexShrink : 0
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   Loading chats...
                              </HUDTyper>
                         </label>
                    </>
               ) : (
                    <>
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : "2.269rem",
                                   color : "#A5A5A5",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "1.69rem",
                                   flexShrink : 0
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   No chats yet!
                              </HUDTyper>
                         </label>
                    </>
               )}
               </div>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "15rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoSupportPanel;