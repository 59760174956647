import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useEffect } from 'react';
import PatrolBuddyGoHUDUtilityManager, {PatrolBuddyGoHUDDataReloader} from "./HUDManagers/PatrolBuddyGoHUDUtilityManager.js";
import PatrolBuddyGoAuthenticationPanel from "./HUDPages/PatrolBuddyGoAuthenticationPanel.js";
import PatrolBuddyGoAccountCreationPanel from "./HUDPages/PatrolBuddyGoAccountCreationPanel.js";
import PatrolBuddyGoSupportChatViewPanel from "./HUDPages/PatrolBuddyGoSupportChatViewPanel.js";
import PatrolBuddyGoSupportAddChatPanel from "./HUDPages/PatrolBuddyGoSupportAddChatPanel.js";
import PatrolBuddyGoPatrolDataHubPanel from "./HUDPages/PatrolBuddyGoPatrolDataHubPanel.js";
import PatrolBuddyGoGlobalViewPanel from "./HUDPages/PatrolBuddyGoGlobalViewPanel.js";
import PatrolBuddyGoSupportPanel from "./HUDPages/PatrolBuddyGoSupportPanel.js";
import PatrolBuddyGoPBGViewPanel from "./HUDPages/PatrolBuddyGoPBGViewPanel.js";
import PatrolBuddyGoControlPanel from "./HUDPages/PatrolBuddyGoControlPanel.js";
import PatrolBuddyGoProductPanel from "./HUDPages/PatrolBuddyGoProductPanel.js";
import { ScrollHandlingProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDVault } from "@trap_stevo/legendarybuilderproreact-ui";
import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";
import "@trap_stevo/legendarybuilderproreact-ui/HUDStyles";
import "./HUDStyles/GRAnimations.css";
import "./HUDStyles/grScrollBar.css";
import "./HUDStyles/hudInputs.css";
import "./HUDStyles/hudFonts.css";











function App() 
{
  const { credentials, vaultOnline, vaultOffline, downloadVaultAccess, updatePassword, saveCredential, syncCredentials, syncToCredentials } = useHUDVault();
  
  const {signedUser, setSignedUser, signedIn, signIn, signOut} = useAuth();
  
  const {sessionCache, setSessionCache, addToSessionCache, clearSessionCache} = useHUDSession();
  
  useEffect(() => {
       if (!PatrolBuddyGoHUDUtilityManager.CheckLocalStorageTemporanceInterval(5))
       {
            PatrolBuddyGoHUDDataReloader(setSignedUser, setSessionCache, signIn);
       }
       
       downloadVaultAccess();
       
       setTimeout(async () => {
            /*await saveCredential("HUDWasabi", {
                 secretAccessKey : "NeE9O1LzcXDn02heFO95eUtENS6udEzzE89zob92",
                 accessKeyId : "3QYR3NZQ1SYTCGD82ZAY"
            }, true, true, "WasabiSCGR");*/
            
            //await syncCredentials();
            
            //await syncToCredentials();
            console.log(credentials);
            await vaultOnline(false, null, true);
       }, 1000);
       
       return;
  }, []);
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div>
         <noscript>Welcome to PatrolBuddyGo!</noscript>
         
         <div id="root"></div>
         
         <script type="application/ld+json">
              {`
                   {
                         "@context": "https://schema.org",
                         "@type": "LLC",
                         "url": "https://patrolbuddygo.com",
                         "name": "PatrolBuddyGo",
                         "description": "A product of Generalized Robotics, empowering police departments.",
                         "logo": "https://patrolbuddygo.com/HUDPatrolBuddyGoIcon.png"
                   }
             `}
         </script>
         
         <Router>
              <ScrollHandlingProvider>
                   <PBGRouter sessionCache = {sessionCache} />
              </ScrollHandlingProvider>
         </Router>
    </div>
  );
}

const PBGRouter = ({ sessionCache }) => {
  const pbgViewPanelConfigurationSettings = sessionCache.CurrentPBGView ? sessionCache.CurrentPBGView : {};
  
  const location = useLocation();
  
  
  
  
  
  
  
  
  
  
  
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Routes location={location}>
          <Route path = "/" element = {<PatrolBuddyGoProductPanel />} />
          
          <Route path = "/authentication" element = {<PatrolBuddyGoAuthenticationPanel />} />
          
          <Route path = "/patrol-data-hub" element = {<PatrolBuddyGoPatrolDataHubPanel />} />
          
          <Route path = "/control-panel" element = {<PatrolBuddyGoControlPanel />} />
          
          <Route path = "/global-view/:county/:serialKey" element = {<PatrolBuddyGoPBGViewPanel {...pbgViewPanelConfigurationSettings} />} />
          
          <Route path = "/global-view" element = {<PatrolBuddyGoGlobalViewPanel />} />
          
          <Route path = "/support/new-chat" element = {<PatrolBuddyGoSupportAddChatPanel />} />
          
          <Route path = "/support/chat" element = {<PatrolBuddyGoSupportChatViewPanel />} />
          
          <Route path = "/support" element = {<PatrolBuddyGoSupportPanel />} />
          
          <Route path = "/account-creation" element = {<PatrolBuddyGoAccountCreationPanel />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;

